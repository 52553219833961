// src/config/config.js

const env = process.env.REACT_APP_ENV || "dev";

const config = {
  local: {
    apiUrl: "http://localhost:8081",
  },
  dev: {
    apiUrl: "https://dev-api.homeschoolplanner.com",
  },
  stg: {
    apiUrl: "https://stg-api.homeschoolplanner.com",
  },
  prd: {
    apiUrl: "https://api.homeschoolplanner.com",
  },
};

export default config[env].apiUrl;
