// TimezoneSelectorModal.js
import React, { useState } from "react";
import { Button, Typography, Modal } from "@mui/material";
import TimeZoneSelect from "../components/TimeZoneSelect";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "fit-content",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const TimezoneSelectorModal = ({ isOpen, handleConfirmTimezone }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const classes = useStyles();

  return (
    <Modal open={isOpen}>
      <div className={classes.modalContainer}>
        <Typography variant="h6" gutterBottom>
          Select a Timezone
        </Typography>
        <br />
        <TimeZoneSelect
          onChange={(tz) => setSelectedTimezone(tz)}
          initialValue={selectedTimezone}
          required
        />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConfirmTimezone(selectedTimezone)}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default TimezoneSelectorModal;
