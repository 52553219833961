import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Stack,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import capitalize from "lodash/capitalize";
import { Wizard, useWizard } from "react-use-wizard";
import { createTerm, updateTerm } from "../../api/termApi";
import { getCurrentTime, getFullDate } from "../../utils/utils";

dayjs.extend(utc);

const INITIAL_INSTRUCTION_HOURS = {
  SUNDAY: {
    isSelected: false,
    value: [
      {
        startTime: "",
        endTime: "",
      },
    ],
  },
  MONDAY: {
    isSelected: true,
    value: [
      {
        startTime: "09:00",
        endTime: "12:00",
      },
    ],
  },
  TUESDAY: {
    isSelected: true,
    value: [
      {
        startTime: "09:00",
        endTime: "12:00",
      },
    ],
  },
  WEDNESDAY: {
    isSelected: true,
    value: [
      {
        startTime: "09:00",
        endTime: "12:00",
      },
    ],
  },
  THURSDAY: {
    isSelected: true,
    value: [
      {
        startTime: "09:00",
        endTime: "12:00",
      },
    ],
  },
  FRIDAY: {
    isSelected: true,
    value: [
      {
        startTime: "09:00",
        endTime: "12:00",
      },
    ],
  },
  SATURDAY: {
    isSelected: false,
    value: [
      {
        startTime: "",
        endTime: "",
      },
    ],
  },
};

const SchoolTermModal = ({ open, onSave, onCancel, term }) => {
  const { selectedAccountId } = useSelector((state) => state.auth);
  const [instructionHours, setInstructionHours] = useState(
    INITIAL_INSTRUCTION_HOURS
  );
  const [values, setValues] = useState({
    name: "",
    startDate: "",
    endDate: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (term) {
      setValues({
        name: term.name,
        startDate: term.startDate,
        endDate: term.endDate,
      });
    }
  }, [term]);

  if (!open) {
    return null;
  }

  const handleSubmit = async () => {
    const { name, startDate, endDate } = values;
    const selectedInstructionHours = Object.keys(instructionHours).reduce(
      (result, key) => {
        if (instructionHours[key].isSelected) {
          result[key] = instructionHours[key];
        }
        return result;
      },
      {}
    );

    let mappedInstructionHours = {};
    Object.keys(selectedInstructionHours).forEach((key) => {
      mappedInstructionHours[`${key}`] = selectedInstructionHours[key].value;
    });

    const instructionHoursData = {
      hours: mappedInstructionHours,
    };

    setIsSubmitting(true);
    if (term?.id) {
      await updateTerm(
        term?.id,
        startDate,
        endDate,
        instructionHoursData,
        name
      );
    } else {
      await createTerm(
        selectedAccountId,
        startDate,
        endDate,
        instructionHoursData,
        name
      );
    }
    setIsSubmitting(false);
    onSave();
  };

  const handleAddDayInstructionHour = (day) => {
    const dayHours = instructionHours[day].value;
    dayHours.push({
      startTime: "",
      endTime: "",
    });
    setInstructionHours({
      ...instructionHours,
      [day]: {
        ...instructionHours[day],
        value: dayHours,
      },
    });
  };

  const handleRemoveDayInstructionHour = (day, index) => {
    const dayHours = instructionHours[day].value;
    dayHours.splice(index, 1);
    setInstructionHours({
      ...instructionHours,
      [day]: {
        ...instructionHours[day],
        value: dayHours,
      },
    });
  };

  const handleChangeStartTime = (day, index, value) => {
    const timeValue = getCurrentTime(value);
    const dayHours = instructionHours[day].value;
    dayHours[index].startTime = timeValue;
    setInstructionHours({
      ...instructionHours,
      [day]: {
        ...instructionHours[day],
        value: dayHours,
      },
    });
  };

  const handleChangeEndTime = (day, index, value) => {
    const timeValue = getCurrentTime(value);
    const dayHours = instructionHours[day].value;
    dayHours[index].endTime = timeValue;
    setInstructionHours({
      ...instructionHours,
      [day]: {
        ...instructionHours[day],
        value: dayHours,
      },
    });
  };

  const handleSelectInstructionDay = (day, event) => {
    setInstructionHours({
      ...instructionHours,
      [day]: {
        ...instructionHours[day],
        isSelected: event.target.checked,
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="term-modal-title"
      aria-describedby="term-modal-description"
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "700px",
        },
      }}
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        {term?.id ? "Edit School Term" : "Create School Term"}
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        <Wizard
          footer={
            <Footer isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
          }
        >
          <Step1 values={values} setValues={setValues} />
          <Step2
            instructionHours={instructionHours}
            handleAddDayInstructionHour={handleAddDayInstructionHour}
            handleSelectInstructionDay={handleSelectInstructionDay}
            handleChangeStartTime={handleChangeStartTime}
            handleChangeEndTime={handleChangeEndTime}
            handleRemoveDayInstructionHour={handleRemoveDayInstructionHour}
          />
        </Wizard>
      </DialogContent>
    </Dialog>
  );
};

const Step1 = ({ values, setValues }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Name"
          required
          fullWidth
          value={values["name"]}
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          variant="outlined"
          sx={{ width: "100%" }}
          label="Start Date"
          required
          timezone={"UTC"}
          value={dayjs.utc(values["startDate"])}
          onChange={(e) => {
            setValues({
              ...values,
              startDate: e.format(),
              endDate: e.format(),
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePicker
          variant="outlined"
          sx={{ width: "100%" }}
          label="End Date"
          required
          timezone={"UTC"}
          value={dayjs.utc(values["endDate"])}
          onChange={(e) => setValues({ ...values, endDate: e.format() })}
        />
      </Grid>
    </Grid>
  );
};

const Step2 = ({
  instructionHours,
  handleSelectInstructionDay,
  handleAddDayInstructionHour,
  handleChangeStartTime,
  handleChangeEndTime,
  handleRemoveDayInstructionHour,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl
          component="fieldset"
          sx={{
            width: "100%",
            padding: "8px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "4px",
          }}
        >
          <FormLabel component="legend">Instruction Hours</FormLabel>
          <FormGroup>
            {Object.keys(instructionHours).map((key) => (
              <Grid container key={key} sx={{ marginBottom: "16px" }}>
                <Grid item xs={4}>
                  <Stack direction="row">
                    <FormControlLabel
                      label={capitalize(key)}
                      control={
                        <Checkbox
                          checked={instructionHours[key].isSelected}
                          onChange={(event) =>
                            handleSelectInstructionDay(key, event)
                          }
                        />
                      }
                    />
                    <IconButton
                      aria-label="add"
                      size="small"
                      color="primary"
                      onClick={() => handleAddDayInstructionHour(key)}
                    >
                      <AddCircleIcon />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={8}>
                  <Stack direction="column" spacing={2}>
                    {instructionHours[key].value.map((hour, index) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        key={`${key}-${index}`}
                      >
                        <TimePicker
                          label="Start"
                          value={dayjs(getFullDate(hour.startTime))}
                          onChange={(val) =>
                            handleChangeStartTime(key, index, val.format())
                          }
                          slotProps={{
                            textField: {
                              placeholder: "hh:mm am/pm",
                              error: false,
                            },
                          }}
                        />
                        <Typography>To</Typography>
                        <TimePicker
                          label="End"
                          value={dayjs(getFullDate(hour.endTime))}
                          onChange={(val) =>
                            handleChangeEndTime(key, index, val.format())
                          }
                          slotProps={{
                            textField: {
                              placeholder: "hh:mm am/pm",
                              error: false,
                            },
                          }}
                        />
                        <IconButton
                          aria-label="remove"
                          size="small"
                          color="error"
                          onClick={() =>
                            handleRemoveDayInstructionHour(key, index)
                          }
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const Footer = ({ isSubmitting, handleSubmit }) => {
  const { activeStep, previousStep, nextStep } = useWizard();

  const handleBack = () => {
    previousStep();
  };

  const handleNext = () => {
    nextStep();
  };

  return (
    <Stack
      direction={"row"}
      justifyContent={"end"}
      spacing={2}
      sx={{ mt: 2, py: 2 }}
    >
      {activeStep === 1 && (
        <Button
          color="inherit"
          onClick={handleBack}
          sx={{ mr: 1 }}
          startIcon={<NavigateBeforeIcon />}
        >
          Previous
        </Button>
      )}
      <Button
        variant={activeStep === 0 ? "" : "contained"}
        endIcon={activeStep === 0 ? <NavigateNextIcon /> : null}
        disabled={isSubmitting}
        loading={isSubmitting}
        onClick={activeStep === 0 ? handleNext : handleSubmit}
      >
        {activeStep === 0 ? "Next" : "Save"}
      </Button>
    </Stack>
  );
};

export default SchoolTermModal;
