import React from "react";

const PrivacyPolicy = () => (
  <div className="privacyPolicy">
    <h1>Privacy Policy for HomeschoolPlanner.com</h1>
    <p>
      <strong>Effective Date:</strong> April 29, 2024
    </p>

    <h2>1. Introduction</h2>
    <p>
      Thank you for choosing HomeschoolPlanner.com. We are committed to
      protecting the privacy of all our users, including parents, guardians,
      students, and instructors. This Privacy Policy outlines our practices
      concerning the collection, use, and disclosure of your data and the data
      you provide about others.
    </p>

    <h2>2. Information We Collect</h2>
    <p>Including, but not limited to, the following information:</p>
    <ul>
      <li>
        <strong>Personal Identification Information:</strong> Name, contact
        details, and address.
      </li>
      <li>
        <strong>Account Details:</strong> Username, password, and account
        settings.
      </li>
      <li>
        <strong>Demographic Information:</strong> Age, education level, and
        gender of students.
      </li>
      <li>
        <strong>Financial Information:</strong> Credit card details and
        transaction history for subscription services.
      </li>
      <li>
        <strong>Educational Information:</strong> Coursework, grades, and
        assessments for students.
      </li>
      <li>
        <strong>Technical Information:</strong> Log data, browser type, and IP
        addresses.
      </li>
    </ul>

    <h2>3. How We Use Your Information</h2>
    <p>The information we collect serves several purposes:</p>
    <ul>
      <li>To provide, operate, and maintain our services.</li>
      <li>
        To understand how you use our services and to tailor them according to
        your needs.
      </li>
      <li>
        To contact you with updates, educational materials, and other
        information related to our services.
      </li>
      <li>To protect our services and maintain their integrity.</li>
    </ul>

    <h2>4. Sharing Your Information</h2>
    <p>Your information may be shared under the following circumstances:</p>
    <ul>
      <li>
        With service providers who perform services on our behalf and require
        access to such information to do their work.
      </li>
      <li>When necessary to comply with the law or protect our rights.</li>
      <li>In connection with a merger, acquisition, or asset sale.</li>
    </ul>

    <h2>5. Data Protection</h2>
    <p>
      We implement robust security measures designed to protect your personal
      information. However, despite our safeguards, no security measures are
      completely impregnable.
    </p>

    <h2>6. Your Privacy Rights</h2>
    <p>
      You have rights to access, correct, or delete your personal information.
      You also have the right to restrict how we use your personal information
      and to object to its processing.
    </p>

    <h2>7. Data Relating to Minors</h2>
    <p>
      As a platform used by families for homeschooling, we collect information
      about minors under the guidance of their parents or legal guardians. It is
      the responsibility of the parent or guardian to provide consent for a
      minor's data to be used in accordance with this policy.
    </p>

    <h2>8. Changes to This Policy</h2>
    <p>
      We may update this policy periodically to reflect new legal, regulatory,
      or operational requirements. We will provide notice of any significant
      changes.
    </p>

    <h2>9. Contact Us</h2>
    <p>
      If you have any questions about this policy or our data practices, please
      contact us at support@homeschoolplanner.com.
    </p>
  </div>
);

export default PrivacyPolicy;
