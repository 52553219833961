// InviteUserModal.js
import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { validateEmail } from "../utils/validation";
import { createNewUser } from "../api/userApi";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: '10px',
    marginTop: theme.spacing(2),
  },
  error: {
    color: "red",
    marginTop: theme.spacing(2),
  },
}));

const InviteUserModal = ({ title, open, onCancel, onSave, userRole }) => {
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  if (!open) {
    return null;
  }

  const handleSaveUser = () => {
    if (!newUser.firstName || !newUser.lastName || !newUser.email) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    createNewUser(newUser, selectedAccountId, userRole)
      .then(() => {
        // Handle successful API call, e.g., show success message, refresh user list, etc.
        setErrorMessage("");
        setNewUser({
          firstName: "",
          lastName: "",
          email: "",
        });
        onSave();
      })
      .catch((error) => {
        // Handle API call failure, e.g., show error message, etc.
        setErrorMessage("Failed to create user. Please try again.");
      });
  };

  const handleCancel = () => {
    setNewUser({
      firstName: "",
      lastName: "",
      email: "",
    });
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="invite-user-modal-title"
      aria-describedby="invite-user-modal-description"
      fullWidth
      maxWidth="sm"
    >
      {title && (
        <DialogTitle style={{ cursor: "move" }} id="dialog-title">
          {title}
        </DialogTitle>
      )}
      <DialogContent dividers sx={{ p: 2 }}>
        <form className={classes.form}>
          <TextField
            label="First Name"
            value={newUser.firstName}
            onChange={(e) =>
              setNewUser({ ...newUser, firstName: e.target.value })
            }
            required
          />
          <TextField
            label="Last Name"
            value={newUser.lastName}
            onChange={(e) =>
              setNewUser({ ...newUser, lastName: e.target.value })
            }
            required
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            error={!!(newUser.email && !validateEmail(newUser.email))}
            helperText={
              newUser.email && !validateEmail(newUser.email) && "Invalid email"
            }
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveUser}
            >
              Save
            </Button>
          </div>
        </form>
        {errorMessage && <p className={classes.error}>{errorMessage}</p>}
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserModal;
