import React from "react";
import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { useWizard } from "react-use-wizard";

const WizardHeader = ({ steps, skipped }) => {
  const { activeStep } = useWizard();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default WizardHeader;
