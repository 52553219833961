import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector, useDispatch } from "react-redux";
import { googleLogout } from "@react-oauth/google";
import DrawerComponent from "./DrawerComponent";
import { logout } from "../api/authApi";
import { setAuthInfo } from "../redux/authSlice";

const navItems = [
  {
    name: "Pricing",
    route: "/pricing",
  },
  {
    name: "Support",
    route: "/home",
  },
];

const HeaderBar = () => {
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const openLogoutModal = () => setLogoutModalOpen(true);
  const closeLogoutModal = () => setLogoutModalOpen(false);

  const dispatch = useDispatch();
  const handleLogout = () => {
    googleLogout();
    dispatch(
      setAuthInfo({
        token: null
      })
    ); // Set token to null upon successful logout
    dispatch(logout());
    closeLogoutModal();
    handleClose();
    navigate("/login");
  };

  const handleClickNavItem = (route) => {
    handleClose();
    navigate(route);
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          {loggedIn ? (
            <>
              <DrawerComponent />
              <div style={{ flexGrow: 1 }} /> {/* Add a flexible space */}
              <Typography variant="h6" sx={{ mr: 2 }}>
                Homeschool Planner
              </Typography>{" "}
              {/* Add the decorative title */}
              <IconButton edge="end" color="inherit" onClick={handleMenu}>
                <AccountCircleIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                getContentAnchorEl={null} // Set getContentAnchorEl to null
              >
                <MenuItem onClick={() => handleClickNavItem("/profile")}>
                  Profile
                </MenuItem>
                <MenuItem
                  onClick={() => handleClickNavItem("/account-settings")}
                >
                  Account Settings
                </MenuItem>
                <MenuItem onClick={openLogoutModal}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="#"
                sx={{
                  mr: 2,
                  display: "flex",
                  flexGrow: 1,
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                LOGO
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {navItems.map((item) => (
                  <Button
                    key={item.name}
                    onClick={() => handleClickNavItem(item.route)}
                    sx={{ my: 2, color: "white", display: "block" }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  onClick={() => navigate("/pricing")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Try It Free
                </Button>
                <Button
                  onClick={() => navigate("/login")}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  Login
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      {/* Logout Confirmation Modal */}
      <Modal open={logoutModalOpen} onClose={closeLogoutModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <div>
            <p>Are you sure you want to log out?</p>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button variant="outlined" onClick={closeLogoutModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Stack>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HeaderBar;
