import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography, Grid, Stack, Button, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import SchoolTermModal from "./SchoolTermModal";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
  deleteTerm,
} from "../../api/termApi";
import { useReportContext } from "../../contexts/report-context";

const PAGE_LIMIT = 10;

const SchoolTermsPage = () => {
  const { setMessage } = useReportContext();
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [futurePage, setFuturePage] = useState(0);
  const [pastPage, setPastPage] = useState(0);
  const [totalActivePages, setTotalActivePages] = useState(0);
  const [totalFuturePages, setTotalFuturePages] = useState(0);
  const [totalPastPages, setTotalPastPages] = useState(0);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchAllTerms = useCallback(() => {
    // Fetch active terms
    fetchActiveTerms(activePage, PAGE_LIMIT)
      .then(({ terms, totalPages }) => {
        setActiveTerms(terms);
        setTotalActivePages(totalPages);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch active terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch future terms
    fetchFutureTerms(futurePage, PAGE_LIMIT)
      .then(({ terms, totalPages }) => {
        setFutureTerms(terms);
        setTotalFuturePages(totalPages);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch future terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch past terms
    fetchPastTerms(pastPage, PAGE_LIMIT)
      .then(({ terms, totalPages }) => {
        setPastTerms(terms);
        setTotalPastPages(totalPages);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch past terms",
          severity: "error",
          flag: true,
        });
      });
  }, [activePage, futurePage, pastPage, setMessage]);

  useEffect(() => {
    fetchAllTerms();
  }, [fetchAllTerms]);

  const handleCreateTerm = () => {
    setSelectedTerm(null);
    setIsOpenModal(true);
  };

  const handleSave = async () => {
    fetchAllTerms();
    setSelectedTerm(null);
    setIsOpenModal(false);
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    setSelectedTerm(null);
  };

  const handlePreviousPage = (type) => {
    if (type === "active") {
      setActivePage((prevPage) => prevPage - 1);
    } else if (type === "future") {
      setFuturePage((prevPage) => prevPage - 1);
    } else if (type === "past") {
      setPastPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = (type) => {
    if (type === "active") {
      setActivePage((prevPage) => prevPage + 1);
    } else if (type === "future") {
      setFuturePage((prevPage) => prevPage + 1);
    } else if (type === "past") {
      setPastPage((prevPage) => prevPage + 1);
    }
  };

  const handleOpenTermEditModal = (rowData) => {
    setSelectedTerm(rowData);
    setIsOpenModal(true);
  };

  const handleRemoveTerm = async (id) => {
    await deleteTerm(id);
    fetchAllTerms();
    setSelectedTerm(null);
  };

  const CustomNoRowsOverlay = (text) => {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {text}
      </Box>
    );
  };

  const columns = [
    { field: "name", headerName: "Title", flex: 1 },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      renderCell: (params) =>
        params.value ? dayjs(params.value).format("YYYY-MM-DD") : "",
    },
    {
      field: "endDate",
      headerName: "End Date",
      flex: 1,
      renderCell: (params) =>
        params.value ? dayjs(params.value).format("YYYY-MM-DD") : "",
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Button
              variant="outlined"
              onClick={() => handleOpenTermEditModal(params.row)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveTerm(rowId)}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" gutterBottom>
              School Terms
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateTerm}
            >
              Add School Term
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Active Terms
          </Typography>
          <Paper>
            <DataGrid
              rows={activeTerms}
              columns={columns}
              autoHeight={true}
              slots={{
                noRowsOverlay: () => CustomNoRowsOverlay("No active terms."),
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: PAGE_LIMIT },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Future Terms
          </Typography>
          <Paper>
            <DataGrid
              rows={futureTerms}
              columns={columns}
              autoHeight={true}
              slots={{
                noRowsOverlay: () => CustomNoRowsOverlay("No future terms."),
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: PAGE_LIMIT },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Past Terms
          </Typography>
          <Paper>
            <DataGrid
              rows={pastTerms}
              columns={columns}
              autoHeight={true}
              slots={{
                noRowsOverlay: () => CustomNoRowsOverlay("No past terms."),
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: PAGE_LIMIT },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
      </Grid>
      <SchoolTermModal
        open={isOpenModal}
        onSave={handleSave}
        onCancel={handleCancel}
        term={selectedTerm}
      />
    </Box>
  );
};

export default SchoolTermsPage;
