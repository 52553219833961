import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Avatar,
  Stack,
  TextField,
  Card,
  CardContent,
  Checkbox,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { profileValidationSchema } from "../../utils/validation";
import {
  connectGoogleCalendar,
  disconnectGoogleCalendar,
  getGoogleCalendars,
  selectGoogleCalendars,
} from "../../api/calendarApi";
import { useReportContext } from "../../contexts/report-context";

const ProfilePage = () => {
  const { loggedInUser, googleCalendarConnected, reconnectGoogleCalendar } =
    useSelector((state) => state.auth);
  const { setMessage } = useReportContext();
  const [editProfile, setEditProfile] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);
  const [userGoogleCalendars, setUserGoogleCalendars] = useState([]);
  const [selectedGoogleCalendars, setSelectedGoogleCalendars] = useState([]);

  useEffect(() => {
    if (googleCalendarConnected && reconnectGoogleCalendar) {
      setIsConnected(true);
      setIsReconnect(true);
    } else if (googleCalendarConnected && !reconnectGoogleCalendar) {
      setIsConnected(true);
      setIsReconnect(false);
    } else {
      setIsConnected(false);
      setIsReconnect(false);
    }
  }, [googleCalendarConnected, reconnectGoogleCalendar]);

  useEffect(() => {
    fetchGoogleCalendars();
  }, []);

  const fetchGoogleCalendars = async () => {
    try {
      const googleCalendars = await getGoogleCalendars();
      setUserGoogleCalendars(googleCalendars);
    } catch (err) {
      setMessage({
        text: "Failed to fetch the google calendars",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleSubmit = async (values) => {
    const { firstName, lastName, email, phoneNumber } = values;
    try {
      setEditProfile(false);
    } catch (err) {
      console.error("Error updating the profile", err);
    }
  };

  const handleCancel = () => {
    setEditProfile(false);
  };

  const handleDisconnectGoogleCalendar = async () => {
    try {
      await disconnectGoogleCalendar();
      setIsConnected(false);
      setIsReconnect(false);
      setMessage({
        text: "Disconnected successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to disconnect the google account",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleConnectGoogleCalendar = useGoogleLogin({
    onSuccess: (codeResponse) => {
      connectGoogleCalendar(codeResponse.code)
        .then((data) => {
          setIsConnected(true);
          setIsReconnect(false);
          fetchGoogleCalendars();
          setMessage({
            text: "Connected the google account successfully!",
            severity: "success",
            flag: true,
          });
        })
        .catch((err) => {
          setMessage({
            text: "Google connection failed",
            severity: "error",
            flag: true,
          });
        });
    },
    onError: () => {
      // Handle login errors here
      setMessage({
        text: "Google connection failed",
        severity: "error",
        flag: true,
      });
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar",
  });

  const handleSelectCalendar = (event, calendar) => {
    if (event.target.checked) {
      setSelectedGoogleCalendars([...selectedGoogleCalendars, calendar]);
    } else {
      const existingSelectedCalendars = selectedGoogleCalendars;
      const udpatedCalendars = existingSelectedCalendars.filter(
        (item) => item.id !== calendar.id
      );
      setSelectedGoogleCalendars(udpatedCalendars);
    }
  };

  const handleAddCalendars = async () => {
    try {
      await selectGoogleCalendars(selectedGoogleCalendars);
      setMessage({
        text: "Added the google calendars successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to select the google calendars",
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <Stack direction="row" justifyContent="center">
          <Avatar
            src="/assets/images/avatar.png"
            sx={{ width: 100, height: 100 }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ position: "absolute", right: 0, top: "20px" }}
        >
          {!editProfile && (
            <Button variant="outlined" onClick={() => setEditProfile(true)}>
              Edit
            </Button>
          )}
          {!isConnected && !isReconnect && (
            <Button variant="contained" onClick={handleConnectGoogleCalendar}>
              Connect Google
            </Button>
          )}

          {isReconnect && isConnected && (
            <Button variant="contained" onClick={handleConnectGoogleCalendar}>
              Reconnect Google
            </Button>
          )}

          {isConnected && !isReconnect && (
            <Button
              variant="contained"
              onClick={handleDisconnectGoogleCalendar}
            >
              Disconnect Google
            </Button>
          )}
        </Stack>
      </Grid>
      {editProfile ? (
        <Grid item xs={12}>
          <Formik
            onSubmit={handleSubmit}
            initialValues={{
              firstName: loggedInUser.firstName,
              lastName: loggedInUser.lastName,
              email: loggedInUser.email,
              phoneNumber: loggedInUser.phoneNumber,
            }}
            validationSchema={profileValidationSchema}
          >
            {({ isSubmitting, touched, errors }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field name="firstName">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="First Name"
                            fullWidth
                            error={errors.firstName && touched.firstName}
                            helperText={
                              errors.firstName && touched.firstName
                                ? errors.firstName
                                : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="lastName">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Last Name"
                            fullWidth
                            error={errors.lastName && touched.lastName}
                            helperText={
                              errors.lastName && touched.lastName
                                ? errors.lastName
                                : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="email">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Email"
                            fullWidth
                            error={errors.email && touched.email}
                            helperText={
                              errors.email && touched.email ? errors.email : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <Field name="phoneNumber">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Phone Number"
                            fullWidth
                            error={errors.phoneNumber && touched.phoneNumber}
                            helperText={
                              errors.phoneNumber && touched.phoneNumber
                                ? errors.phoneNumber
                                : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button autoFocus variant="outlined" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5">
              {`${loggedInUser.firstName} ${loggedInUser.lastName}`}
            </Typography>
            <Typography variant="h6">{`${loggedInUser.email}`}</Typography>
            {loggedInUser.phoneNumber && (
              <Typography>{`${loggedInUser.phoneNumber}`}</Typography>
            )}
            {loggedInUser.timezone && (
              <Typography>{`${loggedInUser.timezone}`}</Typography>
            )}
          </Stack>
        </Grid>
      )}
      {userGoogleCalendars.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Google Calendars
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
              sx={{ flexWrap: "wrap" }}
            >
              {userGoogleCalendars.map((calendar) => (
                <Card
                  key={calendar.id}
                  sx={{
                    width: "350px",
                    cursor: "pointer",
                  }}
                >
                  <CardContent>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                      <Checkbox
                        checked={
                          selectedGoogleCalendars.filter(
                            (item) => item.id === calendar.id
                          ).length > 0
                            ? true
                            : false
                        }
                        onChange={(e) => handleSelectCalendar(e, calendar)}
                        sx={{ marginTop: "-5px !important" }}
                      />
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          {calendar.name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          {calendar.description}
                        </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCalendars}
              disabled={selectedGoogleCalendars.length === 0}
            >
              Add the selected calendars
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProfilePage;
