import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { useMutation } from "react-query";
import { useLogin } from "../../hooks/useLogin";
import { useReportContext } from "../../contexts/report-context";
import { updateUserTimezone } from "../../api/userApi";
import { signInByGoogle, acceptInvite } from "../../api/authApi";
import { useLocation } from "react-router-dom";
import AccountSelectorModal from "../../modals/AccountSelectorModal";
import TimezoneSelectorModal from "../../modals/TimezoneSelectorModal";

const InvitePage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");
  const { setMessage } = useReportContext();
  const {
    login,
    isLoading,
    error,
    showTimezoneModal,
    setShowTimezoneModal,
    showAccountSelectorModal,
    setShowAccountSelectorModal,
    authResponse,
    setAuthResponse,
    accountSelected,
  } = useLogin();

  // Use the useMutation hook with the acceptInvite function
  const acceptInviteMutation = useMutation(acceptInvite);

  // Use the useLocation hook to get the current URL
  const location = useLocation();

  // Use the URLSearchParams interface to parse the invite ID from the URL
  const inviteId = new URLSearchParams(location.search).get("invite_id");

  const handleLoginSubmit = async () => {
    login({
      username,
      password,
      inviteId,
    });
  };

  const handleCreateAccountSubmit = () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    acceptInviteMutation.mutate({
      username,
      password,
      inviteId,
    });
  };

  const handleGoogleSignInSuccess = async (data) => {
    try {
      const response = await signInByGoogle(
        {
          credential: data.credential,
          rememberMe: true,
        },
        inviteId
      );
      setAuthResponse(response);
    } catch (err) {
      setMessage({
        text: "Google Signin failed",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleGoogleSignInFailed = (error) => {
    setMessage({
      text: "Google Signin failed",
      severity: "error",
      flag: true,
    });
  };

  const handleConfirmTimezone = async (selectedTimezone) => {
    try {
      const updatedUser = await updateUserTimezone(
        authResponse.loggedInUser.id,
        selectedTimezone
      );
      setShowTimezoneModal(false);
      setAuthResponse({ ...authResponse, loggedInUser: updatedUser });
    } catch (error) {
      setMessage({
        text: "Failed to update timezone. Please try again.",
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <Box>
      <h1>Accept Invite</h1>

      <h2>Existing Account</h2>
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleLoginSubmit}>
        Accept Invite
      </Button>

      <h2>Create Account</h2>
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateAccountSubmit}
      >
        Accept Invite
      </Button>

      <h2>Google Sign In</h2>
      <GoogleLogin
        buttonText="Sign in with Google"
        onSuccess={handleGoogleSignInSuccess}
        onFailure={handleGoogleSignInFailed}
      />
      <AccountSelectorModal
        authResponse={authResponse}
        isOpen={showAccountSelectorModal}
        handleConfirmAccount={accountSelected}
      />
      <TimezoneSelectorModal
        isOpen={showTimezoneModal}
        handleConfirmTimezone={handleConfirmTimezone}
      />
    </Box>
  );
};

export default InvitePage;
