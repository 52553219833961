import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CreateClassModal from "./CreateClassModal";
import {
  getClassTemplates,
  deleteClassTemplate,
  searchClassTemplates,
} from "../../api/classTemplateApi";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
} from "../../api/termApi";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { setClassTemplates } from "../../redux/classTemplateSlice";
import { useReportContext } from "../../contexts/report-context";

const ClassesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.classTemplate.templates);
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const [openClassModal, setOpenClassModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [students, setStudents] = useState([]);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [selectedTermId, setSelectedTermId] = useState("");

  useEffect(() => {
    fetchClassTemplates();
  }, []);

  useEffect(() => {
    if (selectedAccountId) {
      fetchStudents();
    }
  }, [selectedAccountId]);

  const fetchClassTemplates = async () => {
    try {
      const data = await getClassTemplates(currentPage, pageSize);
      dispatch(setClassTemplates(data.content));
    } catch (err) {
      setMessage({
        text: "Failed to fetch class templates",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchAllSchoolTerms = useCallback(() => {
    // Fetch active terms
    fetchActiveTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setActiveTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch active terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch future terms
    fetchFutureTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setFutureTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch future terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch past terms
    fetchPastTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setPastTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch past terms",
          severity: "error",
          flag: true,
        });
      });
  }, [setMessage]);

  useEffect(() => {
    fetchAllSchoolTerms();
  }, [fetchAllSchoolTerms]);

  const fetchStudents = async () => {
    try {
      const users = await getUsers(selectedAccountId, ROLE_STUDENT);
      setStudents(users);
    } catch (error) {
      setMessage({
        text: "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleOpenCreateModal = () => {
    setSelectedRowData(null);
    setOpenClassModal(true);
  };

  const handleCloseClassModal = () => {
    setOpenClassModal(false);
  };

  const handleUpdateClassTemplates = () => {
    fetchClassTemplates();
    setOpenClassModal(false);
  };

  const handleOpenDetailsPage = (id) => {
    navigate(`/classes/${id}`);
  };

  const handleRemoveRow = async (id) => {
    try {
      await deleteClassTemplate(id);
      handleUpdateClassTemplates();
    } catch (err) {
      setMessage({
        text: "Failed to remove a class template",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleRowClick = (params) => {
    const classId = params.row.id;
    handleOpenDetailsPage(classId);
  };

  const handleSelectStudent = async (event) => {
    const studentId = event.target.value;
    setSelectedStudentId(studentId);
    const searchParam = {
      studentId: studentId,
      schoolTermId: selectedTermId,
    };
    try {
      const data = await searchClassTemplates(0, 10, "ASC", searchParam);
      dispatch(setClassTemplates(data.content));
    } catch (err) {
      setMessage({
        text: "Failed to fetch class templates",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleSelectTerm = async (event) => {
    const termId = event.target.value;
    setSelectedTermId(termId);
    const searchParam = {
      studentId: selectedStudentId,
      schoolTermId: termId,
    };
    try {
      const data = await searchClassTemplates(0, 10, "ASC", searchParam);
      dispatch(setClassTemplates(data.content));
    } catch (err) {
      setMessage({
        text: "Failed to fetch class templates",
        severity: "error",
        flag: true,
      });
    }
  };

  const columns = [
    { field: "name", headerName: "Title", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "durationPerLesson",
      headerName: "Duration Per Lesson (minutes)",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveRow(rowId)}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h4" gutterBottom>
                Classes
              </Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Student</em>;
                  }

                  const foundStudent = students.find(
                    (item) => item.id === selected
                  );
                  return `${foundStudent?.firstName} ${foundStudent?.lastName}`;
                }}
                onChange={handleSelectStudent}
              >
                <MenuItem value="">All</MenuItem>
                {students.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
              </Select>
              <Select
                size="small"
                displayEmpty
                fullWidth
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Term</em>;
                  }
                  const foundTerm = [
                    ...activeTerms,
                    ...futureTerms,
                    ...pastTerms,
                  ].find((item) => item.id === selected);
                  return foundTerm?.name;
                }}
                onChange={handleSelectTerm}
              >
                <MenuItem value="">All</MenuItem>
                {[...activeTerms, ...futureTerms, ...pastTerms].map(
                  (option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </Stack>

            <Button variant="contained" onClick={handleOpenCreateModal}>
              Create Class
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={templates}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: pageSize },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
              onRowClick={handleRowClick}
            />
          </Paper>
        </Grid>
      </Grid>
      <CreateClassModal
        open={openClassModal}
        handleUpdate={handleUpdateClassTemplates}
        handleClose={handleCloseClassModal}
        data={selectedRowData}
      />
    </Box>
  );
};

export default ClassesPage;
