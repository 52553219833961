// StealthMode.js
import React from "react";
import { useNavigate } from "react-router-dom";

const StealthMode = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/home");
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        src="/assets/images/comingsoon.png"
        alt="Stealth Mode"
        style={{ width: "100%" }}
      />
      <button
        onClick={handleButtonClick}
        style={{ position: "absolute", top: "50%", left: "50%", opacity: 0 }}
      >
        Enter
      </button>
    </div>
  );
};

export default StealthMode;
