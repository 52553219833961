// authApi.js
import { api } from "./api";
import apiUrl from "../config/config.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { parseJwt } from "../utils/utils";
import { setAuthInfo } from "../redux/authSlice";

export const doPerformLogin = async (credentials) => {
  try {
    const url = `${apiUrl}/api/v1/auth/login`;
    const response = await api.post(url, JSON.stringify(credentials));

    if (response.status === 200) {
      const {
        token,
        user,
        accounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      } = response.data;

      const decodedToken = parseJwt(token); // Extract loggedInUserRoles from the token
      const loggedInUserRoles = decodedToken.roles || [];
      const loggedInUserAccounts = accounts || [];
      const loggedInUser = user || {};

      return {
        token,
        loggedInUser,
        loggedInUserRoles,
        loggedInUserAccounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      };
    } else {
      throw new Error(`Login failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Login error: ${error.message}`);
  }
};

const doPerformLogout = async (token) => {
  try {
    const response = await api.post(`${apiUrl}/api/v1/auth/logout`, {});

    if (response.status === 200) {
      return true;
    } else {
      throw new Error(`Logout failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Logout error: ${error.message}`);
  }
};

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, thunkAPI) => {
    try {
      const { token, loggedInUser, loggedInUserRoles, loggedInUserAccounts } =
        await doPerformLogin(credentials);
      // Store the token and loggedInUserRoles in redux upon successful authentication
      thunkAPI.dispatch(
        setAuthInfo({
          token,
          loggedInUser,
          loggedInUserRoles,
          loggedInUserAccounts,
        })
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    await doPerformLogout();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const signInByGoogle = async (credentials, inviteId) => {
  try {
    const url = inviteId
      ? `${apiUrl}/api/v1/auth/google-signin?invite_id=${inviteId}`
      : `${apiUrl}/api/v1/auth/google-signin`;

    const response = await api.post(url, JSON.stringify(credentials));

    if (response.status === 200) {
      const {
        token,
        user,
        accounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      } = response.data;

      const decodedToken = parseJwt(token); // Extract loggedInUserRoles from the token
      const loggedInUserRoles = decodedToken.roles || [];
      const loggedInUserAccounts = accounts || [];
      const loggedInUser = user || {};

      return {
        token,
        loggedInUser,
        loggedInUserRoles,
        loggedInUserAccounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      };
    } else {
      throw new Error(`Login failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Login error: ${error.message}`);
  }
};

export const acceptInvite = async (credentials) => {
  try {
    const url = `${apiUrl}/api/v1/auth/accept-invite`;
    const response = await api.post(url, JSON.stringify(credentials));

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Accept invite failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Accept invite error: ${error.message}`);
  }
};
