import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import { signInByGoogle } from "../api/authApi";
import { updateUserTimezone } from "../api/userApi";
import { useSelector } from "react-redux";
import { useReportContext } from "../contexts/report-context";
import TimezoneSelectorModal from "../modals/TimezoneSelectorModal";
import AccountSelectorModal from "../modals/AccountSelectorModal";
import { useLogin } from "../hooks/useLogin";

const Login = () => {
  const { setMessage } = useReportContext();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const errorMessage = useSelector((state) => state.errorMessage);
  const {
    login,
    isLoading,
    error,
    showTimezoneModal,
    setShowTimezoneModal,
    showAccountSelectorModal,
    setShowAccountSelectorModal,
    authResponse,
    setAuthResponse,
    accountSelected,
  } = useLogin();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    login(formData);
  };

  const handlePasswordKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleGoogleSignInSuccess = async (data) => {
    try {
      const response = await signInByGoogle({
        credential: data.credential,
        rememberMe: true,
      });
      setAuthResponse(response);
    } catch (err) {
      setMessage({
        text: "Google Signin failed",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleGoogleSignInFailed = (error) => {
    setMessage({
      text: "Google Signin failed",
      severity: "error",
      flag: true,
    });
  };

  const handleConfirmTimezone = async (selectedTimezone) => {
    try {
      const updatedUser = await updateUserTimezone(
        authResponse.loggedInUser.id,
        selectedTimezone
      );
      setShowTimezoneModal(false);
      setAuthResponse({ ...authResponse, loggedInUser: updatedUser });
    } catch (error) {
      setMessage({
        text: "Failed to update timezone. Please try again.",
        severity: "error",
        flag: true,
      });
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        {errorMessage && (
          <Typography variant="body1" color="error" gutterBottom>
            {errorMessage}
          </Typography>
        )}
        <form>
          <TextField
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <br />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            onKeyDown={handlePasswordKeyDown}
            margin="normal"
            required
          />
          <br />
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Login
          </Button>
          <br />
        </form>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: "50px",
          }}
        >
          <GoogleLogin
            onSuccess={handleGoogleSignInSuccess}
            onError={handleGoogleSignInFailed}
          />
        </div>
      </div>
      <AccountSelectorModal
        authResponse={authResponse}
        isOpen={showAccountSelectorModal}
        handleConfirmAccount={accountSelected}
      />
      <TimezoneSelectorModal
        isOpen={showTimezoneModal}
        handleConfirmTimezone={handleConfirmTimezone}
      />
    </div>
  );
};

export default Login;
