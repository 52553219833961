// App.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CssBaseline } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import { ToastContainer } from "react-toastify";
import HeaderBar from "./components/HeaderBar";
import Login from "./components/Login";
import Home from "./pages/home";
import Users from "./pages/users";
import UserDetailsPage from "./pages/users/UserDetails";
import PricingPage from "./pages/pricing";
import CalendarEventsPage from "./pages/calendar-events";
import ClassesPage from "./pages/classes";
import ClassDetailsPage from "./pages/classes/ClassDetails";
import SchoolTermsPage from "./pages/school-terms";
import AccountSettings from "./pages/account-settings";
import ProfilePage from "./pages/profile";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsOfService from "./pages/terms-of-service";
import StealthMode from "./pages/stealth";
import InvitePage from "./pages/invite";
import {
  expandedDrawerWidth,
  collapsedDrawerWidth,
  LOCAL_STORAGE_KEY,
} from "./config/constants";
import { setAuthInfo } from "./redux/authSlice";
import theme from "./theme";
import { ReportContextProvider } from "./contexts/report-context";
import ReportGenerator from "./pages/reports";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    padding: useTheme().spacing(3),
    transition: useTheme().transitions.create(["margin", "width"], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    marginLeft: collapsedDrawerWidth,
    width: `calc(100% - ${collapsedDrawerWidth}px)`,
  },
  contentShift: {
    transition: useTheme().transitions.create(["margin", "width"], {
      easing: useTheme().transitions.easing.easeOut,
      duration: useTheme().transitions.duration.enteringScreen,
    }),
    marginLeft: expandedDrawerWidth,
    width: `calc(100% - ${expandedDrawerWidth}px)`,
  },
  contentZeroMargin: {
    flexGrow: 1,
    padding: useTheme().spacing(3),
    transition: useTheme().transitions.create(["margin", "width"], {
      easing: useTheme().transitions.easing.sharp,
      duration: useTheme().transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: "100%",
  },
}));

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const drawerOpen = useSelector((state) => state.drawer.drawerOpen);
  const isLoggedIn = useSelector((state) => state.auth.loggedIn);

  useEffect(() => {
    // first check local storage for the homeschool-planner-auth object to see if the user is already logged in
    const authInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (authInfo) {
      dispatch(setAuthInfo(authInfo));
      return;
    }
  }, [dispatch, location]);

  const shouldZeroMargin = () => {
    const path = location.pathname;
    if (
      path === "/" ||
      path === "/pricing" ||
      path === "/support" ||
      path.startsWith("/invite")
    ) {
      return true;
    }
    if (path === "/home" && !isLoggedIn) {
      return true;
    }
    return false;
  };

  const shouldHideHeader = () => {
    const path = location.pathname;
    return path === "/" || path.startsWith("/invite");
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ReportContextProvider>
              <CssBaseline />
              {!shouldHideHeader() && <HeaderBar />}
              <main
                className={clsx({
                  [classes.content]: !shouldZeroMargin(),
                  [classes.contentShift]: drawerOpen && !shouldZeroMargin(),
                  [classes.contentZeroMargin]: shouldZeroMargin(),
                })}
              >
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route
                    path="/calendar-events"
                    element={<CalendarEventsPage />}
                  />
                  <Route path="/terms" element={<SchoolTermsPage />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/:id" element={<UserDetailsPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/pricing" element={<PricingPage />} />
                  <Route path="/classes" element={<ClassesPage />} />
                  <Route path="/classes/:id" element={<ClassDetailsPage />} />
                  <Route path="/reports" element={<ReportGenerator />} />
                  <Route
                    path="/account-settings"
                    element={<AccountSettings />}
                  />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/invite" element={<InvitePage />} />
                  <Route path="/" element={<StealthMode />} />
                </Routes>
              </main>
            </ReportContextProvider>
          </LocalizationProvider>
        </ThemeProvider>
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
};

export default App;
