// AccountSelectorModal.js
import React, { useState } from "react";
import { Button, Typography, Modal, Select, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "fit-content",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const AccountSelectorModal = ({
  authResponse,
  isOpen,
  handleConfirmAccount,
}) => {
  const [selectedAccount, setSelectedAccount] = useState("");
  const classes = useStyles();

  const handleAccountSelect = (e) => {
    setSelectedAccount(e.target.value);
  };

  return (
    <Modal open={isOpen}>
      <div className={classes.modalContainer}>
        <Typography variant="h6" gutterBottom>
          Select an Account
        </Typography>
        <br />

        <Select
          value={selectedAccount}
          onChange={handleAccountSelect}
          displayEmpty
          fullWidth
          required
        >
          {authResponse &&
            authResponse.loggedInUserAccounts &&
            authResponse.loggedInUserAccounts.length > 1 &&
            authResponse.loggedInUserAccounts.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.name}
              </MenuItem>
            ))}
        </Select>

        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConfirmAccount(selectedAccount)}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default AccountSelectorModal;
