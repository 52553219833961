import * as React from "react";
import { useContext, useState } from "react";
import AppSnackbar from "../components/AppSnackBar";

export const ReportContext = React.createContext();

export const ReportContextProvider = ({ children }) => {
  const [message, setMessage] = useState({
    text: undefined,
    severity: "error",
    flag: false,
  });

  const value = { message, setMessage };

  return (
    <ReportContext.Provider value={value}>
      <AppSnackbar message={message} />
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => {
  const data = useContext(ReportContext);

  if (!data) return {};

  const { message, setMessage } = data;

  return { message, setMessage };
};
