import React, { useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  Checkbox,
  Stack,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useReportContext } from "../../contexts/report-context";
import { createCalendarEventValidationSchema } from "../../utils/validation";
import { updateCalendarEvent } from "../../api/calendarApi";

const EventDetailsViewModal = ({ event, open, handleClose }) => {
  const { setMessage } = useReportContext();
  const [isEdit, setIsEdit] = useState(false);

  if (!open) {
    return null;
  }

  const handleUpdateEvent = async (values) => {
    const { name, userStartTime, userEndTime } = values;
    try {
      const updatedEventData = {
        ...event,
        name: name,
        userStartTime: userStartTime,
        userEndTime: userEndTime,
      };
      await updateCalendarEvent(updatedEventData.id, updatedEventData);
      handleClose();
      setMessage({
        text: "Updated the event successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to updated the event",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="create-event-modal-title"
      aria-describedby="create-event-modal-description"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        {isEdit ? "Update" : "View"} Event
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        {isEdit ? (
          <Formik
            onSubmit={handleUpdateEvent}
            initialValues={{
              name: event.title,
              userStartTime: event.userStartTime,
              userEndTime: event.userEndTime,
            }}
            validationSchema={createCalendarEventValidationSchema}
          >
            {({ isSubmitting, touched, errors, values, setValues }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field name="name">
                        {({ field }) => (
                          <TextField
                            {...field}
                            label="Name"
                            size="small"
                            fullWidth
                            error={errors.name && touched.name}
                            helperText={
                              errors.name && touched.name ? errors.name : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="userStartTime">
                        {({ field }) => (
                          <DateTimePicker
                            variant="outlined"
                            sx={{ width: "100%" }}
                            label="Start Time"
                            required
                            timezone={"UTC"}
                            slotProps={{
                              textField: {
                                size: "small",
                                error:
                                  errors.userStartTime && touched.userStartTime,
                                helperText:
                                  errors.userStartTime && touched.userStartTime
                                    ? errors.userStartTime
                                    : "",
                              },
                            }}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                userStartTime: e.format(),
                              })
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="userEndTime">
                        {({ field }) => (
                          <DateTimePicker
                            variant="outlined"
                            sx={{ width: "100%" }}
                            label="End Time"
                            required
                            timezone={"UTC"}
                            slotProps={{
                              textField: {
                                size: "small",
                                error:
                                  errors.userEndTime && touched.userEndTime,
                                helperText:
                                  errors.userEndTime && touched.userEndTime
                                    ? errors.userEndTime
                                    : "",
                              },
                            }}
                            onChange={(e) =>
                              setValues({ ...values, userEndTime: e.format() })
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="instructionalTimeBlock">
                        {({ field }) => (
                          <FormControlLabel
                            label="Block Instructional Time"
                            control={<Checkbox defaultChecked />}
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Stack
                    direction={"row"}
                    justifyContent={"end"}
                    spacing={2}
                    sx={{ mt: 2 }}
                  >
                    <Button
                      autoFocus
                      variant="outlined"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">Name: </Typography>
                <Typography variant="bodyFV">{event.title}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">Start Time: </Typography>
                <Typography variant="bodyFV">
                  {dayjs(event.start).format("YYYY-MM-DD")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">End Time: </Typography>
                <Typography variant="bodyFV">
                  {dayjs(event.end).format("YYYY-MM-DD")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">Event Type: </Typography>
                <Typography variant="bodyFV">{event.type}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">Event State: </Typography>
                <Typography variant="bodyFV">{event.state}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFL">Event Status: </Typography>
                <Typography variant="bodyFV">{event.status}</Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      {!isEdit && (
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal}>
            Close
          </Button>
          {event.source === "GENERIC" && (
            <Button
              variant="contained"
              onClick={() => setIsEdit(true)}
              autoFocus
            >
              Edit
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default EventDetailsViewModal;
