import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Checkbox,
  Stack,
  FormControlLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useSelector, useDispatch } from "react-redux";
import { createCalendarEvent } from "../../api/calendarApi";
import { createCalendarEventValidationSchema } from "../../utils/validation";
import { addCalendarEvent } from "../../redux/calendarEventsSlice";
import { useReportContext } from "../../contexts/report-context";

const CreateCalendarEventModal = ({ open, handleClose }) => {
  const { loggedInUser } = useSelector((state) => state.auth);
  const { setMessage } = useReportContext();
  const dispatch = useDispatch();

  if (!open) {
    return null;
  }

  const handleCreateEvent = async (values) => {
    const { name, userStartTime, userEndTime } = values;
    try {
      const eventData = {
        name: name,
        userId: loggedInUser.id,
        participants: [],
        userStartTime: userStartTime,
        userEndTime: userEndTime,
        timezone: "UTC",
        recurrenceType: "NON_RECURRING",
        source: "GENERIC",
        checklistStatus: "PENDING"
      };
      const response = await createCalendarEvent(eventData);
      const mappedEvent = {
        ...response,
        title: response.name,
        start: new Date(response.userStartTime),
        end: new Date(response.userEndTime),
      };
      dispatch(addCalendarEvent(mappedEvent));
      handleClose();
      setMessage({
        text: "Created an event successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to create an event",
        severity: "error",
        flag: true,
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-event-modal-title"
      aria-describedby="create-event-modal-description"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        Create Event
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        <Formik
          onSubmit={handleCreateEvent}
          initialValues={{
            name: "",
            userStartTime: "",
            userEndTime: "",
          }}
          validationSchema={createCalendarEventValidationSchema}
        >
          {({ isSubmitting, touched, errors, values, setValues }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Title"
                          size="small"
                          fullWidth
                          error={errors.name && touched.name}
                          helperText={
                            errors.name && touched.name ? errors.name : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="userStartTime">
                      {({ field }) => (
                        <DateTimePicker
                          variant="outlined"
                          sx={{ width: "100%" }}
                          label="Start Time"
                          required
                          timezone={"UTC"}
                          slotProps={{
                            textField: {
                              size: "small",
                              error:
                                errors.userStartTime && touched.userStartTime,
                              helperText:
                                errors.userStartTime && touched.userStartTime
                                  ? errors.userStartTime
                                  : "",
                            },
                          }}
                          onChange={(e) =>
                            setValues({ ...values, userStartTime: e.format() })
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="userEndTime">
                      {({ field }) => (
                        <DateTimePicker
                          variant="outlined"
                          sx={{ width: "100%" }}
                          label="End Time"
                          required
                          timezone={"UTC"}
                          slotProps={{
                            textField: {
                              size: "small",
                              error: errors.userEndTime && touched.userEndTime,
                              helperText:
                                errors.userEndTime && touched.userEndTime
                                  ? errors.userEndTime
                                  : "",
                            },
                          }}
                          onChange={(e) =>
                            setValues({ ...values, userEndTime: e.format() })
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="instructionalTimeBlock">
                      {({ field }) => (
                        <FormControlLabel
                          label="Block Instructional Time"
                          control={<Checkbox defaultChecked />}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button autoFocus variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Create
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateCalendarEventModal;
