import React, { useEffect, useState } from "react";
import Select from "react-select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Add the necessary plugins to Day.js
dayjs.extend(utc);
dayjs.extend(timezone);

const TimeZoneSelect = ({ onChange, initialValue, required }) => {
  const [timezones, setTimezones] = useState([]);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    // Fetch all timezones
    const allTimezones = Intl.supportedValuesOf("timeZone");

    // Transform the timezones into an array of objects for react-select
    const timezoneOptions = allTimezones.map((tz) => ({
      value: tz,
      label: tz,
    }));

    // Update the state with the timezone options
    setTimezones(timezoneOptions);

    // Set the initial value if provided
    if (initialValue) {
      setSelectedTimezone({ value: initialValue, label: initialValue });
    }
  }, [initialValue]);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedTimezone(selectedOption);
      setErrorMessage(false);
      if (onChange) {
        onChange(selectedOption);
      }
    } else {
      setSelectedTimezone(null);
      setErrorMessage(required);
      if (onChange) {
        onChange(null);
      }
    }
  };

  return (
    <div>
      <Select
        options={timezones}
        value={selectedTimezone}
        onChange={handleChange}
        placeholder="Select a timezone"
        isClearable
      />
      {errorMessage && <p style={{ color: "red" }}>Please select a timezone</p>}
      {selectedTimezone && (
        <div>
          <p>Selected Timezone: {selectedTimezone.label}</p>
          <p>
            Current Time:{" "}
            {dayjs().tz(selectedTimezone.value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      )}
    </div>
  );
};

export default TimeZoneSelect;
