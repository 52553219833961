import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Paper,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import QuickstartWizardModal from "../../components/QuickstartWizardModal";
import { useReportContext } from "../../contexts/report-context";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { getChecklist, updateCalendarEvent } from "../../api/calendarApi";

const Home = () => {
  let [searchParams] = useSearchParams();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const [openQuickstartWizardModal, setOpenQuickstartWizardModal] =
    useState(false);
  const [students, setStudents] = useState([]);
  const [selectedAction, setSelectedAction] = useState("PENDING");
  const [checklistItems, setChecklistItems] = useState([]);

  const actionsMenuItems = [
    {
      name: "Complete",
      value: "COMPLETED",
    },
    {
      name: "Skip",
      value: "SKIPPED",
    },
    {
      name: "Postpone",
      value: "PENDING",
    },
  ];

  useEffect(() => {
    if (searchParams.get("showQuickStart") === "true") {
      setOpenQuickstartWizardModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchChecklist(selectedAction);
  }, []);

  useEffect(() => {
    if (selectedAccountId) {
      fetchStudents();
    }
  }, [selectedAccountId]);

  const fetchStudents = async () => {
    try {
      const users = await getUsers(selectedAccountId, ROLE_STUDENT);
      setStudents(users);
    } catch (error) {
      setMessage({
        text: "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchChecklist = async (status) => {
    try {
      const response = await getChecklist(status);
      setChecklistItems(response.events);
    } catch (error) {
      setMessage({
        text: "Failed to fetch checklist",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleCloseModal = () => {
    setOpenQuickstartWizardModal(false);
  };

  const handleSelectStudent = (event) => {
    const studentId = event.target.value;
    const originalChecklistItems = checklistItems;
    const filteredChecklistItems = originalChecklistItems.filter(
      (item) => item.userId === studentId
    );
    setChecklistItems(filteredChecklistItems);
  };

  const handleSelectAction = (event) => {
    const action = event.target.value;
    setSelectedAction(action);
    fetchChecklist(action);
  };

  const handleChangeEventStatus = async (data, status) => {
    try {
      const updatedChecklistData = {
        ...data,
        checklistStatus: status,
      };
      await updateCalendarEvent(data.id, updatedChecklistData);
      setMessage({
        text: "Updated the checklist status successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to update the checklist status",
        severity: "error",
        flag: true,
      });
    }
  };

  const columns = [
    { field: "title", headerName: "Title", flex: 1 },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        return `${dayjs(data?.userStartTime).format("HH:mm a")} - ${dayjs(
          data?.userEndTime
        ).format("HH:mm a")}`;
      },
    },
    {
      field: "student",
      headerName: "Student",
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        return `${data?.userFirstName} ${data?.userLastName}`;
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            {data?.checklistStatus !== "COMPLETED" && (
              <Tooltip title="Complete" placement="top" arrow>
                <IconButton
                  onClick={() => handleChangeEventStatus(data, "COMPLETED")}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </Tooltip>
            )}
            {data?.checklistStatus !== "PENDING" && (
              <Tooltip title="Postpone" placement="top" arrow>
                <IconButton
                  onClick={() => handleChangeEventStatus(data, "PENDING")}
                >
                  <SkipNextOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {data?.checklistStatus !== "SKIPPED" && (
              <Tooltip title="Skip" placement="top" arrow>
                <IconButton
                  onClick={() => handleChangeEventStatus(data, "SKIPPED")}
                >
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>
            Checklist
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Select
              size="small"
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Select Student</em>;
                }

                const foundStudent = students.find(
                  (item) => item.id === selected
                );
                return `${foundStudent?.firstName} ${foundStudent?.lastName}`;
              }}
              onChange={handleSelectStudent}
            >
              <MenuItem value="">All</MenuItem>
              {students.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.firstName} {option.lastName}
                </MenuItem>
              ))}
            </Select>
            <Select
              size="small"
              value={selectedAction}
              onChange={handleSelectAction}
            >
              {actionsMenuItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={checklistItems}
              columns={columns}
              autoHeight={true}
              checkboxSelection={true}
              hideFooter={true}
            />
          </Paper>
        </Grid>
      </Grid>
      <QuickstartWizardModal
        open={openQuickstartWizardModal}
        handleClose={handleCloseModal}
      />
    </Box>
  );
};

export default Home;
