// api.js
import axios from "axios";
import apiUrl from "../config/config.js";
import { parseJwt } from "../utils/utils";
import { isTokenExpiringWithinTenMinutes } from "../utils/utils.js";
import { LOCAL_STORAGE_KEY } from "../config/constants.js";
import store from "../redux/store.js";
import { setAuthInfo } from "../redux/authSlice.js";

export const doPerformRefresh = async (newToken) => {
  try {
    let response;
    if (!newToken) {
      response = await api.post(`${apiUrl}/api/v1/auth/refresh`, {});
    } else {
      response = await api.post(
        `${apiUrl}/api/v1/auth/refresh`,
        {},
        {
          headers: {
            Authorization: newToken ? `Bearer ${newToken}` : undefined,
          },
        }
      );
    }

    if (response.status === 200) {
      const {
        token,
        user,
        accounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      } = response.data;
      const decodedToken = parseJwt(token); // Extract session info from the token
      const loggedInUserRoles = decodedToken.roles || [];
      const loggedInUserAccounts = accounts || [];
      const loggedInUser = user || {};

      return {
        token,
        loggedInUser,
        loggedInUserRoles,
        loggedInUserAccounts,
        showQuickStart,
        reconnectGoogleCalendar,
        googleCalendarConnected,
      };
    } else {
      throw new Error(`Login failed: ${response.statusText}`);
    }
  } catch (error) {
    throw new Error(`Logout error: ${error.message}`);
  }
};

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    let auth = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    const currentUrl = config.url;

    if (currentUrl.includes("/api/v1/auth/refresh")) {
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      }
      return config;
    }

    if (
      !currentUrl.includes("/api/v1/auth/login") &&
      !currentUrl.includes("/api/v1/auth/logout") &&
      !currentUrl.includes("/api/v1/auth/google-signin") &&
      (!auth || !auth.token)
    ) {
      store.dispatch(
        setAuthInfo({
          token: null,
        })
      );
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      window.location.href = "#/login";
    } else if (auth && auth.token) {
      if (isTokenExpiringWithinTenMinutes(auth.token)) {
        try {
          const { token: newToken } = await doPerformRefresh();
          auth = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
          if (!auth) {
            throw new Error("Auth object does not exist in local storage");
          }
          auth.token = newToken;
          localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(auth));
          config.headers.Authorization = newToken
            ? `Bearer ${newToken}`
            : config.headers.Authorization;
        } catch (error) {
          store.dispatch(
            setAuthInfo({
              token: null,
            })
          );
          localStorage.removeItem(LOCAL_STORAGE_KEY);
          window.location.href = "#/login";
        }
      } else if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`;
      } else {
        store.dispatch(
          setAuthInfo({
            token: null,
          })
        );
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        window.location.href = "#/login";
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
