import * as Yup from "yup";

export function validateEmail(email) {
  if (!email) {
    return false; // handle empty string and null
  }

  // Regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailPattern.test(email);
}

export const profileValidationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().required(),
  phoneNumber: Yup.string().required(),
});

export const createCalendarEventValidationSchema = Yup.object({
  name: Yup.string().required(),
  userStartTime: Yup.string().required(),
  userEndTime: Yup.string().required(),
});

export const createClassValidationSchema = Yup.object({
  name: Yup.string().required(),
  subject: Yup.string().optional(),
  description: Yup.string().optional(),
  durationPerLesson: Yup.number().min(0).max(480).integer(),
  numberOfLessons: Yup.number().min(0).max(365).integer(),
  lessonFrequency: Yup.string().required(),
});

export const createLessonValidationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string(),
  workItems: Yup.array().of(
    Yup.object({
      description: Yup.string().required(),
    })
  ),
});

export const termValidationSchema = Yup.object({
  name: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
});

export const enrollUserValidationSchema = Yup.object({
  termId: Yup.string().required(),
  studentId: Yup.string().required(),
});

export const enrollStudentValidationSchema = Yup.object({
  termId: Yup.string().required(),
  classTemplateId: Yup.string().required(),
});