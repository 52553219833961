import React from "react";
import { Box, Button } from "@mui/material";
import { useWizard } from "react-use-wizard";

const WizardFooter = ({ skipped, setSkipped }) => {
  const { activeStep, stepCount, previousStep, nextStep } = useWizard();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    nextStep();
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    previousStep();
  };

  const handleSkip = () => {
    nextStep();
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          variant="outlined"
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="outlined"
          color="inherit"
          onClick={handleSkip}
          sx={{ mr: 1 }}
        >
          Skip
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {activeStep === stepCount - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default WizardFooter;
