import { api } from "./api";
import apiUrl from "../config/config.js";

export const getCalendarEvents = async (
  start_date = getOneMonthAgo(),
  end_date = getOneMonthAhead(),
  direction = "ASC"
) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/calendar/events`, {
      params: {
        start_date,
        end_date,
        direction,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createCalendarEvent = async (data) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/calendar/event?rebalance=true`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCalendarEvent = async (id, data) => {
  try {
    const response = await api.put(
      `${apiUrl}/api/v1/calendar/event/${id}?rebalance=true`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const connectGoogleCalendar = async (authCode) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/calendar-connect/google?authCode=${authCode}`,
      {}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const disconnectGoogleCalendar = async () => {
  try {
    const response = await api.delete(
      `${apiUrl}/api/v1/calendar-connect/google`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGoogleCalendars = async () => {
  try {
    const response = await api.get(
      `${apiUrl}/api/v1/calendar-connect/google/calendars`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const selectGoogleCalendars = async (data) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/calendar-connect/google/calendar-selection`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getChecklist = async (
  checklist_status = "PENDING",
  start_date = getOneMonthAgo(),
  end_date = getOneMonthAhead(),
  direction = "ASC"
) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/calendar/events`, {
      params: {
        start_date,
        end_date,
        direction,
        checklist_items_only: true,
        checklist_status,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getOneMonthAgo = () => {
  const today = new Date();
  const oneMonthAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );
  return oneMonthAgo.toISOString();
};

const getOneMonthAhead = () => {
  const today = new Date();
  const oneMonthAhead = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  return oneMonthAhead.toISOString();
};
