// store.js
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk"; // Import redux-thunk
import authReducer from "./authSlice";
import drawerReducer from "./drawerSlice";
import calendarEventsReducer from "./calendarEventsSlice"
import classTemplateReducer from "./classTemplateSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  drawer: drawerReducer,
  calendarEvents: calendarEventsReducer,
  classTemplate: classTemplateReducer
  // Add other reducers if any
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
