import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Stack,
  IconButton,
  FormControl,
  FormLabel,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field, FieldArray } from "formik";
import { createLessonValidationSchema } from "../../utils/validation";
import {
  createClassLesson,
  updateClassLesson,
} from "../../api/classLessonsApi";
import { useReportContext } from "../../contexts/report-context";

const CreateLessonModal = ({
  open,
  handleCreate,
  handleClose,
  data,
  classTemplateId,
}) => {
  const { setMessage } = useReportContext();

  if (!open) {
    return null;
  }

  const handleCreateLesson = async (values) => {
    const { title, description, workItems } = values;
    try {
      const lessonData = {
        title: title,
        description: description,
        workItems: workItems,
        classTemplateId: classTemplateId,
      };
      if (data && data.id) {
        await updateClassLesson(data.id, lessonData);
        setMessage({
          text: "Updated the class lesson successfully!",
          severity: "success",
          flag: true,
        });
      } else {
        await createClassLesson(lessonData);
        setMessage({
          text: "Created the class lesson successfully!",
          severity: "success",
          flag: true,
        });
      }

      handleCreate();
    } catch (err) {
      setMessage({
        text: "Failed to create a class lesson",
        severity: "error",
        flag: true,
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="lesson-modal-title"
      aria-describedby="lesson-modal-description"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        {data ? "Edit" : "Create"} Lesson
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        <Formik
          onSubmit={handleCreateLesson}
          initialValues={{
            title: data ? data.title : "",
            description: data ? data.description : "",
            workItems: data ? data.workItems : [{ description: "" }],
          }}
          validationSchema={createLessonValidationSchema}
        >
          {({ isSubmitting, touched, errors, values, handleChange }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="title">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Title"
                          size="small"
                          fullWidth
                          error={errors.title && touched.title}
                          helperText={
                            errors.title && touched.title ? errors.title : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="description">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Description"
                          size="small"
                          fullWidth
                          multiline
                          rows={4}
                          error={errors.description && touched.description}
                          helperText={
                            errors.description && touched.description
                              ? errors.description
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      sx={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "4px",
                      }}
                    >
                      <FormLabel component="legend">Work Items</FormLabel>
                      <FieldArray name="workItems">
                        {({ insert, remove, push }) => (
                          <Grid container spacing={2}>
                            {values.workItems.length > 0 &&
                              values.workItems.map((workItem, index) => (
                                <Grid item xs={12} key={index}>
                                  <Stack
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"center"}
                                  >
                                    <Field
                                      name={`workItems.${index}.description`}
                                    >
                                      {({ field }) => (
                                        <TextField
                                          {...field}
                                          label="Description"
                                          size="small"
                                          fullWidth
                                          required
                                          onChange={(e) => handleChange(e)}
                                        />
                                      )}
                                    </Field>
                                    <IconButton
                                      aria-label="remove"
                                      size="small"
                                      color="error"
                                      onClick={() => remove(index)}
                                    >
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  </Stack>
                                </Grid>
                              ))}
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => push({ description: "" })}
                              >
                                Add workitem
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </FieldArray>
                    </FormControl>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button autoFocus variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    {data ? "Save" : "Create"}
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateLessonModal;
