import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Stack, Button, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import InviteUserModal from "../../components/InviteUserModal";
import {
  ROLE_STUDENT,
  getUsers,
  deleteUser,
  getInviteUsers,
  deleteInviteUser,
} from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";

const StudentsComponent = () => {
  const navigate = useNavigate();
  const { setMessage } = useReportContext();
  const [students, setStudents] = useState([]);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );

  useEffect(() => {
    if (selectedAccountId) {
      fetchStudents();
      fetchInviteUsers();
    }
  }, [selectedAccountId, showStudentModal]);

  const fetchStudents = async () => {
    try {
      const users = await getUsers(selectedAccountId, ROLE_STUDENT);
      setStudents(users);
    } catch (error) {
      setMessage({
        text: "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchInviteUsers = async () => {
    try {
      const inviteUsersData = await getInviteUsers(
        selectedAccountId,
        ROLE_STUDENT
      );
      const mappedInviteUsers = inviteUsersData.content.map((user) => {
        return {
          id: user.id,
          firstName: user.personFirstName,
          lastName: user.personLastName,
          email: user.personEmail,
          status: user.status,
        };
      });
      setInviteUsers(mappedInviteUsers);
    } catch (error) {
      setMessage({
        text: "Failed to fetch invite users",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      await deleteUser(selectedAccountId, id);
      fetchStudents();
      setMessage({
        text: "Removed a user successfully!",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      setMessage({
        text: "Failed to remove a user",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleAddStudent = () => {
    setShowStudentModal(true);
  };

  const handleCancelStudentModal = () => {
    setShowStudentModal(false);
  };

  const handleSaveStudentModal = () => {
    // Logic to save the new student
    setShowStudentModal(false);
  };

  const handleDeleteInviteUser = async (id) => {
    try {
      await deleteInviteUser(id);
      fetchInviteUsers();
      setMessage({
        text: "Removed the invited user successfully!",
        severity: "success",
        flag: true,
      });
    } catch (error) {
      setMessage({
        text: "Failed to delete the invited user",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleRowClick = (params) => {
    const userId = params.row.id;
    navigate(`/users/${userId}`);
  };

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            {rowData?.status === "PENDING" ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteInviteUser(rowData.id)}
              >
                Cancel Invite
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteUser(rowData.id)}
              >
                Delete
              </Button>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" gutterBottom>
              Students
            </Typography>
            <Button variant="contained" onClick={handleAddStudent}>
              Add Student
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={[...inviteUsers, ...students]}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
              onRowClick={handleRowClick}
            />
          </Paper>
        </Grid>
      </Grid>
      <InviteUserModal
        title="Add Student"
        open={showStudentModal}
        onCancel={handleCancelStudentModal}
        onSave={handleSaveStudentModal}
        userRole={ROLE_STUDENT}
      />
    </Box>
  );
};

export default StudentsComponent;
