// DrawerComponent.js
import { useDispatch, useSelector } from "react-redux";
import {
  ThemeProvider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import Timelapse from "@mui/icons-material/Timelapse";
import MenuIcon from "@mui/icons-material/Menu";
import SchoolIcon from "@mui/icons-material/School";
import FaceIcon from "@mui/icons-material/Face";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Link } from "react-router-dom";
import theme from "../theme";
import { toggleDrawer } from "../redux/drawerSlice";
import { expandedDrawerWidth, collapsedDrawerWidth } from "../config/constants";

const DrawerComponent = () => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector((state) => state.drawer.drawerOpen);
  const { loggedInUserRoles } = useSelector((state) => state.auth); // Retrieve loggedIn and loggedInUserRoles attributes from redux

  const drawerItems = [
    { text: "Checklist", icon: <HomeIcon />, path: "/home" },
    {
      text: "Calendar",
      icon: <CalendarIcon />,
      path: "/calendar-events",
    },
    { text: "Students", icon: <FaceIcon />, path: "/users" },
    {
      text: "School Terms",
      icon: <Timelapse />,
      path: "/terms",
    },
    { text: "Classes", icon: <SchoolIcon />, path: "/classes" },
    { text: "Reports", icon: <AssessmentIcon />, path: "/reports" },
  ];

  const adminDrawerItems = [
    { text: "Checklist", icon: <HomeIcon />, path: "/home" },
    { text: "Calendar", icon: <CalendarIcon />, path: "/calendar-events" },
    { text: "Students", icon: <FaceIcon />, path: "/users" },
    { text: "School Terms", icon: <Timelapse />, path: "/terms" },
    { text: "Classes", icon: <SchoolIcon />, path: "/classes" },
    { text: "Reports", icon: <AssessmentIcon />, path: "/reports" },
  ];

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer());
  };

  const hasRole = (role) => {
    if (!loggedInUserRoles) {
      return false;
    }
    return loggedInUserRoles.includes(role);
  };

  const listItemStyles = {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: "16px",
    width: drawerOpen ? expandedDrawerWidth : collapsedDrawerWidth,
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        ModalProps={{ keepMounted: true }}
      >
        <div>
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </div>
        <List>
          <>
            {hasRole("ROLE_ADMIN") ? (
              <>
                {adminDrawerItems.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    // Remove the "to" prop to prevent routing if item.path is null
                    to={item.path ? item.path : undefined}
                    // Remove the "component" prop to not set component={Link} if item.path is null
                    component={item.path ? Link : undefined}
                    onClick={item.onClick}
                    style={listItemStyles}
                  >
                    <ListItemIcon style={{ minWidth: "0px" }}>
                      {item.icon}
                    </ListItemIcon>
                    {drawerOpen && (
                      <ListItemText
                        style={{ paddingLeft: "16px" }}
                        primary={item.text}
                      />
                    )}
                  </ListItem>
                ))}
              </>
            ) : (
              drawerItems.map((item) => (
                <ListItem
                  button
                  key={item.text}
                  // Remove the "to" prop to prevent routing if item.path is null
                  to={item.path ? item.path : undefined}
                  // Remove the "component" prop to not set component={Link} if item.path is null
                  component={item.path ? Link : undefined}
                  onClick={item.onClick}
                  style={listItemStyles}
                >
                  <ListItemIcon style={{ minWidth: "0px" }}>
                    {item.icon}
                  </ListItemIcon>
                  {drawerOpen && (
                    <ListItemText
                      style={{ paddingLeft: "16px" }}
                      primary={item.text}
                    />
                  )}
                </ListItem>
              ))
            )}
          </>
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default DrawerComponent;
