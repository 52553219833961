import { api } from "./api.js";
import apiUrl from "../config/config.js";

export const fetchActiveTerms = async (page = 0, size = 10) => {
  const url = `${apiUrl}/api/v1/terms/active`;
  return doFetchTerms(url, page, size);
};

export const fetchPastTerms = async (page = 0, size = 10) => {
  const url = `${apiUrl}/api/v1/terms/past`;
  return doFetchTerms(url, page, size);
};

export const fetchFutureTerms = (page = 0, size = 10) => {
  const url = `${apiUrl}/api/v1/terms/future`;
  return doFetchTerms(url, page, size);
};

export const createTerm = async (
  accountId,
  startDate,
  endDate,
  instructionHours,
  name
) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/terms?account_id=${accountId}`,
      {
        startDate,
        endDate,
        name,
        instructionHours,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTerm = async (
  id,
  startDate,
  endDate,
  instructionHours,
  name
) => {
  try {
    const response = await api.put(`${apiUrl}/api/v1/terms/${id}`, {
      startDate,
      endDate,
      name,
      instructionHours,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const doFetchTerms = async (
  url,
  page = 0,
  size = 10,
  sortDirection = "ASC"
) => {
  try {
    const response = await api.get(url, {
      params: {
        page,
        size,
        sortDirection,
      },
    });
    return {
      terms: response.data.content,
      totalPages: response.data.totalPages,
    };
  } catch (error) {
    throw error;
  }
};

export const deleteTerm = async (id) => {
  try {
    const response = await api.delete(`${apiUrl}/api/v1/terms/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateInstructionHours = async (schoolTermId, data) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/terms/instruction-hours/${schoolTermId}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
