import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { doPerformLogin } from "../api/authApi";
import { setAuthInfo } from "../redux/authSlice";
import { toast } from "react-toastify";

export const useLogin = () => {
  const navigate = useNavigate();
  const [showTimezoneModal, setShowTimezoneModal] = useState(false);
  const [showAccountSelectorModal, setShowAccountSelectorModal] =
    useState(false);
  const [authResponse, setAuthResponse] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authResponse || !authResponse.loggedInUserAccounts) {
      return;
    }
    if (authResponse.loggedInUserAccounts.length === 1) {
      const selectedAccountId = authResponse.loggedInUserAccounts[0].id;
      setShowAccountSelectorModal(false);
      dispatch(
        setAuthInfo({
          ...authResponse,
          selectedAccountId,
        })
      );
      if (!authResponse.loggedInUser.timezone) {
        setShowTimezoneModal(true);
      } else {
        if (authResponse.showQuickStart) {
          navigate("/home", { state: { showQuickStart: true } });
        } else {
          navigate("/home");
        }
      }
    } else if (authResponse.loggedInUserAccounts.length > 1) {
      setShowAccountSelectorModal(true);
    } else {
      // display a toast saying "No license found for this user"
      toast.error("No license found for this user");
    }
  }, [authResponse, dispatch, navigate]);

  const accountSelected = async (selectedAccountId) => {
    setShowAccountSelectorModal(false);
    await dispatch(
      setAuthInfo({
        ...authResponse,
        selectedAccountId,
      })
    );
    if (!authResponse.loggedInUser.timezone) {
      setShowTimezoneModal(true);
    } else {
      if (authResponse.showQuickStart) {
        navigate({
          pathname: "/home",
          search: "?showQuickStart=true",
        });
      } else {
        navigate("/home");
      }
    }
  };

  const loginMutation = useMutation(doPerformLogin, {
    onSuccess: (response) => {
      setAuthResponse(response);
    },
    onError: (error) => {
      // Handle login error
      toast.error("Login failed");
    },
  });

  const login = (credentials) => {
    loginMutation.mutate(credentials);
  };

  return {
    login,
    isLoading: loginMutation.isLoading,
    error: loginMutation.error,
    showTimezoneModal,
    setShowTimezoneModal,
    showAccountSelectorModal,
    setShowAccountSelectorModal,
    authResponse,
    setAuthResponse,
    accountSelected,
  };
};
