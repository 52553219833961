// src/components/CalendarView.js

import React from "react";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";

const localizer = dayjsLocalizer(dayjs);

const CalendarView = ({ events, handleOpenDetailsModal }) => {
  const handleClickEvent = (event) => {
    handleOpenDetailsModal(event);
  };

  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      scrollToTime={new Date(new Date().setHours(8))}
      onSelectEvent={handleClickEvent}
      style={{ height: 500 }}
    />
  );
};

export default CalendarView;
