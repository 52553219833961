import React from "react";
import { Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import Stripe from 'stripe';
import { useReportContext } from "../../contexts/report-context";

const stripe = new Stripe(process.env.REACT_APP_STRIPE_SECRET_KEY)

const AccountSettings = () => {
  const { loggedInUser } = useSelector((state) => state.auth);
  const { setMessage } = useReportContext();

  const getCustomerId = async () => {
    try {
      const customers = await stripe.customers.list({
        email: loggedInUser.email,
      });

      const customerId = customers.data[0].id;
      return customerId;
    } catch (error) {
      setMessage({
        text: "Failed to get a customer id",
        severity: "error",
        flag: true,
      });
      return error;
    }
  };

  const handleManageSubscription = async () => {
    const customerId = await getCustomerId();
    const session = await stripe.billingPortal.sessions.create({
      customer: customerId,
      return_url: `${window.location.origin}/#/home`,
    });
    window.location.href = session.url;
  };
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleManageSubscription}
      >
        Manage Subscription
      </Button>
    </Box>
  );
};

export default AccountSettings;
