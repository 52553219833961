// classTemplateSlice.js
import { createSlice } from "@reduxjs/toolkit";

const classTemplateSlice = createSlice({
  name: "classTemplate",
  initialState: { templates: [] },
  reducers: {
    setClassTemplates: (state, action) => {
      state.templates = action.payload;
    },
    addClassTemplate: (state, action) => {
      state.templates.push(action.payload);
    },
  },
});

export const { setClassTemplates, addClassTemplate } =
  classTemplateSlice.actions;

export default classTemplateSlice.reducer;
