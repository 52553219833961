// theme.js
import { createTheme } from "@mui/material/styles";

// Define your theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: "#f44336",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    bodyFL: {
      fontSize: 18,
      fontWeight: 700
    },
    bodyFV: {
      fontSize: 16,
      fontWeight: 400
    }
  }
});

export default theme;
