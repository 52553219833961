import React from "react";
import { Stack } from "@mui/material";
import StudentsComponent from "./StudentsComponent";
import EducatorsComponent from "./EducatorsComponent";

const Users = () => {
  return (
    <Stack spacing={2}>
      <StudentsComponent />
      {/* <EducatorsComponent /> */}
    </Stack>
  );
};

export default Users;
