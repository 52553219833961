import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Paper,
  Modal,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import CreateClassModal from "./CreateClassModal";
import ApplyToTermModal from "./ApplyToTermModal";
import CreateLessonModal from "./CreateLessonModal";
import {
  getClassTemplateById,
  removeClassTemplateFromTerm,
  getClassInstances,
} from "../../api/classTemplateApi";
import {
  getClassTemplateLessons,
  deleteClassLesson,
} from "../../api/classLessonsApi";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { fetchActiveTerms, fetchFutureTerms } from "../../api/termApi";
import { useReportContext } from "../../contexts/report-context";

const ClassDetailsPage = () => {
  const { id } = useParams();
  const { setMessage } = useReportContext();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const [classTemplateDetails, setClassTemplateDetails] = useState(null);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [classLessons, setClassLessons] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClassLesson, setSelectedClassLesson] = useState(null);
  const [openApplyModal, setOpenApplyModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [openLessonModal, setOpenLessonModal] = useState(false);
  const [openClassModal, setOpenClassModal] = useState(false);
  const [selectedClassInstance, setSelectedClassInstance] = useState(null);
  const [classInstances, setClassInstances] = useState([]);

  useEffect(() => {
    fetchClassTemplateDetails();
    fetchClassLessons();
    fetchClassInstances();
  }, []);

  useEffect(() => {
    let isMounted = true; // add this line

    fetchActiveTerms()
      .then(({ terms }) => {
        if (isMounted) setActiveTerms(terms); // check if component is still mounted
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch active terms",
          severity: "error",
          flag: true,
        });
      });

    fetchFutureTerms()
      .then(({ terms }) => {
        if (isMounted) setFutureTerms(terms); // check if component is still mounted
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch future terms",
          severity: "error",
          flag: true,
        });
      });

    return () => {
      isMounted = false; // set it to false on unmount
    };
  }, []);

  useEffect(() => {
    if (selectedAccountId) {
      fetchStudents();
    }
  }, [selectedAccountId]);

  const fetchClassTemplateDetails = async () => {
    try {
      const response = await getClassTemplateById(id);
      setClassTemplateDetails(response);
    } catch (err) {
      setMessage({
        text: "Failed to fetch class template details",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchClassInstances = async () => {
    try {
      const response = await getClassInstances(id);
      const instances = response.content;
      const mappedClassInstances = [];
      instances.forEach((instance) => {
        instance.students.forEach((student) => {
          mappedClassInstances.push({
            id: student.id,
            studentName: `${student.firstName} ${student.lastName}`,
            termId: instance.termSummary.id,
            termName: instance.termSummary.name,
          });
        });
      });
      setClassInstances(mappedClassInstances);
    } catch (err) {
      setMessage({
        text: "Failed to fetch class instances",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchClassLessons = async () => {
    try {
      const response = await getClassTemplateLessons(id);
      setClassLessons(response.content);
    } catch (err) {
      setMessage({
        text: "Failed to fetch class template lessons",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchStudents = async () => {
    try {
      const users = await getUsers(selectedAccountId, ROLE_STUDENT);
      setStudents(users);
    } catch (error) {
      setMessage({
        text: "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleOpenApplyModal = () => {
    setOpenApplyModal(true);
  };

  const handleCloseApplyModal = () => {
    setOpenApplyModal(false);
  };

  const handleOpenRemoveConfirmModal = (rowData) => {
    setSelectedClassInstance(rowData);
    setOpenRemoveModal(true);
  };

  const handleCloseRemoveConfirmModal = () => {
    setSelectedClassInstance(null);
    setOpenRemoveModal(false);
  };

  const handleRemoveClassFromTerm = async () => {
    try {
      await removeClassTemplateFromTerm(
        id,
        selectedClassInstance?.termId,
        selectedClassInstance?.id
      );
      fetchClassInstances();
      handleCloseRemoveConfirmModal();
      setMessage({
        text: "Removed the class from term successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to remove class from term",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleApplyClassToTerm = () => {
    fetchClassInstances();
    setOpenApplyModal(false);
  };

  const handleOpenLessonModal = () => {
    setOpenLessonModal(true);
  };

  const handleCloseLessonModal = () => {
    setOpenLessonModal(false);
  };

  const handleCreateLesson = () => {
    setOpenLessonModal(false);
    fetchClassLessons();
  };

  const handleOpenLessonEditModal = (rowData) => {
    setSelectedClassLesson(rowData);
    setOpenLessonModal(true);
  };

  const handleOpenEditModal = () => {
    setOpenClassModal(true);
  };

  const handleCloseClassModal = () => {
    setOpenClassModal(false);
  };

  const handleUpdateClassTemplate = () => {
    fetchClassTemplateDetails();
    setOpenClassModal(false);
  };

  const handleRemoveLesson = async (id) => {
    try {
      await deleteClassLesson(id);
      fetchClassLessons();
      setMessage({
        text: "Removed the class template successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to remove a class template",
        severity: "error",
        flag: true,
      });
    }
  };

  const renderWorkItems = (params) => {
    const strArray = params?.value?.map((workItem) => workItem.description);
    return <Box>{strArray.join(", ")}</Box>;
  };

  const lessonColumns = [
    { field: "title", headerName: "Title", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "workItems",
      headerName: "Work Items",
      flex: 1,
      renderCell: renderWorkItems,
    },
    { field: "lessonNumber", headerName: "Lesson Number", flex: 1 },
    {
      field: "durationInMinutes",
      headerName: "Duration (minutes)",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const rowId = params.row.id;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Button
              variant="outlined"
              onClick={() => handleOpenLessonEditModal(params.row)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveLesson(rowId)}
            >
              Delete
            </Button>
          </Stack>
        );
      },
    },
  ];

  const enrollmentColumns = [
    { field: "studentName", headerName: "Student", flex: 1 },
    { field: "termName", headerName: "Term", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const rowData = params.row;
        return (
          <Stack
            direction={"row"}
            alignItems="center"
            spacing={1}
            sx={{ height: "100%" }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleOpenRemoveConfirmModal(rowData)}
            >
              Remove
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box>
      {classTemplateDetails && (
        <>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ mb: 2 }}
          >
            <Typography variant="h4" gutterBottom>
              {classTemplateDetails.name}
            </Typography>
            <Button variant="contained" onClick={handleOpenEditModal}>
              Edit
            </Button>
          </Stack>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="bodyFV">
                {classTemplateDetails.description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFV">Subject:</Typography>
                <Typography variant="bodyFV">
                  {classTemplateDetails.subject}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFV">Duration:</Typography>
                <Typography variant="bodyFV">
                  {classTemplateDetails.durationPerLesson} minutes
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Typography variant="bodyFV">Frequency:</Typography>
                <Typography variant="bodyFV">
                  {classTemplateDetails.lessonFrequency}{" "}
                  {classTemplateDetails.isOngoing ? "- Ongoing" : ""}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" gutterBottom>
              Lessons
            </Typography>
            <Button variant="contained" onClick={handleOpenLessonModal}>
              Create Lesson
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={classLessons}
              columns={lessonColumns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" gutterBottom>
              Enrollment
            </Typography>
            <Box>
              <Button variant="contained" onClick={handleOpenApplyModal}>
                Enroll Student
              </Button>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={classInstances}
              columns={enrollmentColumns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
      </Grid>
      <CreateClassModal
        open={openClassModal}
        handleUpdate={handleUpdateClassTemplate}
        handleClose={handleCloseClassModal}
        data={classTemplateDetails}
      />
      <ApplyToTermModal
        open={openApplyModal}
        classTemplateId={id}
        classTemplateDetails={classTemplateDetails}
        terms={[...activeTerms, ...futureTerms]}
        students={students}
        handleClose={handleCloseApplyModal}
        handleApply={handleApplyClassToTerm}
      />
      <CreateLessonModal
        open={openLessonModal}
        classTemplateId={id}
        data={selectedClassLesson}
        handleCreate={handleCreateLesson}
        handleClose={handleCloseLessonModal}
      />

      {/* Remove class from term confirmation modal */}
      <Modal open={openRemoveModal} onClose={handleCloseRemoveConfirmModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <div>
            <p>Are you sure you want to remove this student from this term?</p>
            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button
                variant="outlined"
                onClick={handleCloseRemoveConfirmModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleRemoveClassFromTerm}
              >
                Remove
              </Button>
            </Stack>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export default ClassDetailsPage;
