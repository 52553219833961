import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCalendarEvents } from "../../api/calendarApi";
import CalendarView from "../../components/CalendarView";
import CreateCalendarEventModal from "./CreateEventModal";
import EventDetailsViewModal from "./EventDetailsViewModal";
import { setCalendarEvents } from "../../redux/calendarEventsSlice";
import { useReportContext } from "../../contexts/report-context";

const CalendarEventsPage = () => {
  const events = useSelector((state) => state.calendarEvents.events);
  const dispatch = useDispatch();
  const { setMessage } = useReportContext();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getCalendarEvents();
        const mappedEvents = response.events.map((event) => {
          return {
            ...event,
            title: event.title,
            start: new Date(event.userStartTime),
            end: new Date(event.userEndTime),
          };
        });
        dispatch(setCalendarEvents(mappedEvents));
      } catch (error) {
        setMessage({
          text: "Failed to fetch the events",
          severity: "error",
          flag: true,
        });
      }
    };

    fetchEvents();
  }, []);

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenDetailsModal = (event) => {
    setSelectedEvent(event);
    setOpenDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" gutterBottom>
              Calendar Page
            </Typography>
            <Button variant="contained" onClick={handleOpenCreateModal}>
              Create Event
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <CalendarView
            events={events}
            handleOpenDetailsModal={handleOpenDetailsModal}
          />
        </Grid>
      </Grid>
      <CreateCalendarEventModal
        open={openCreateModal}
        handleClose={handleCloseCreateModal}
      />
      <EventDetailsViewModal
        open={openDetailsModal}
        event={selectedEvent}
        handleClose={handleCloseDetailsModal}
      />
    </Box>
  );
};

export default CalendarEventsPage;
