import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Wizard, useWizard } from "react-use-wizard";
import { useSelector } from "react-redux";
import { Box, Typography, Grid, Button, Modal } from "@mui/material";
import WizardHeader from "./WizardHeader";
import WizardFooter from "./WizardFooter";
import InviteUserModal from "../InviteUserModal";
import SchoolTermModal from "../../pages/school-terms/SchoolTermModal";
import CreateClassModal from "../../pages/classes/CreateClassModal";
import { ROLE_STUDENT, ROLE_TEACHER } from "../../api/userApi";
import { applyClassTemplateToTerm } from "../../api/classTemplateApi";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
} from "../../api/termApi";

const WIZARD_STEPS = [
  "Add users view",
  "Create School term view",
  "Create Class view",
];

const QuickstartWizardModal = ({ open, handleClose }) => {
  const [skipped, setSkipped] = useState(new Set());

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "800px",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "4px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              Quickstart Wizard
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Wizard
              header={<WizardHeader steps={WIZARD_STEPS} skipped={skipped} />}
              footer={
                <WizardFooter skipped={skipped} setSkipped={setSkipped} />
              }
            >
              <Step1 />
              <Step2 />
              <Step3 />
            </Wizard>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Step1 = () => {
  const { nextStep } = useWizard();
  const [showUserModal, setShowUserModal] = useState(false);
  const [userType, setUserType] = useState("student");

  const handleAddUser = (type) => {
    setUserType(type);
    setShowUserModal(true);
  };

  const handleCancelUserModal = () => {
    setShowUserModal(false);
  };

  const handleSaveUserModal = () => {
    setShowUserModal(false);
    nextStep();
  };

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Students
          </Typography>
          <Button variant="contained" onClick={() => handleAddUser("student")}>
            Add Student
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Educators
          </Typography>
          <Button variant="contained" onClick={() => handleAddUser("educator")}>
            Add Educator
          </Button>
        </Grid>
      </Grid>
      <InviteUserModal
        title={userType === "student" ? "Add Student" : "Add Educator"}
        open={showUserModal}
        onCancel={handleCancelUserModal}
        onSave={handleSaveUserModal}
        userRole={userType === "student" ? ROLE_STUDENT : ROLE_TEACHER}
      />
    </Box>
  );
};

const Step2 = () => {
  const { nextStep } = useWizard();
  const [openTermModal, setOpenTermModal] = useState(false);

  const handleOpenTermModal = () => {
    setOpenTermModal(true);
  };

  const handleCloseTermModal = () => {
    setOpenTermModal(false);
  };

  const handleSaveTerm = () => {
    setOpenTermModal(false);
    nextStep();
  };

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            School Terms
          </Typography>
          <Button variant="contained" onClick={handleOpenTermModal}>
            Create school term
          </Button>
        </Grid>
      </Grid>
      <SchoolTermModal
        open={openTermModal}
        onSave={handleSaveTerm}
        onCancel={handleCloseTermModal}
        term={null}
      />
    </Box>
  );
};
const Step3 = () => {
  const navigate = useNavigate();
  const templates = useSelector((state) => state.classTemplate.templates);
  const [openClassModal, setOpenClassModal] = useState(false);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);

  useEffect(() => {
    fetchActiveTerms()
      .then(({ terms, totalPages }) => {
        setActiveTerms(terms);
      })
      .catch((error) => console.error("Error fetching active terms:", error));

    // Fetch future terms
    fetchFutureTerms()
      .then(({ terms, totalPages }) => {
        setFutureTerms(terms);
      })
      .catch((error) => console.error("Error fetching future terms:", error));

    // Fetch past terms
    fetchPastTerms()
      .then(({ terms, totalPages }) => {
        setPastTerms(terms);
      })
      .catch((error) => console.error("Error fetching past terms:", error));
  }, []);

  const handleOpenCreateModal = () => {
    setOpenClassModal(true);
  };

  const handleCloseClassModal = async () => {
    const fullTerms = [...activeTerms, ...futureTerms, ...pastTerms];
    if (fullTerms.length > 0 && templates.length > 0) {
      try {
        const data = {
          classTemplateId: templates[templates.length - 1].id,
          termId: fullTerms[fullTerms.length - 1].id,
        };
        await applyClassTemplateToTerm(data);
      } catch (err) {
        console.error("Error applying class template", err);
      }
    }

    setOpenClassModal(false);
    navigate("/home");
  };

  const handleUpdateClassTemplates = () => {
    setOpenClassModal(false);
  };

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Class view
          </Typography>
          <Button variant="contained" onClick={handleOpenCreateModal}>
            Add class
          </Button>
        </Grid>
      </Grid>
      <CreateClassModal
        open={openClassModal}
        handleUpdate={handleUpdateClassTemplates}
        handleClose={handleCloseClassModal}
        data={null}
      />
    </Box>
  );
};

export default QuickstartWizardModal;
