import { api } from "./api";
import apiUrl from "../config/config.js";

export const getClassTemplateLessons = async (
  classTemplateId,
  page = 0,
  size = 10,
  direction = "ASC"
) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/v1/class-lesson/template/${classTemplateId}`,
      {
        params: {
          page,
          size,
          direction,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassLessonById = async (id) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/class-lesson/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createClassLesson = async (data) => {
  try {
    const response = await api.post(`${apiUrl}/api/v1/class-lesson`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateClassLesson = async (id, data) => {
  try {
    const response = await api.put(`${apiUrl}/api/v1/class-lesson/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteClassLesson = async (id) => {
  try {
    const response = await api.delete(`${apiUrl}/api/v1/class-lesson/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
