// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEY } from "../config/constants.js";

const loadInitialState = () => {
  const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (savedState) {
    return { ...JSON.parse(savedState), loggedIn: true };
  } else {
    return {
      loggedIn: false,
      token: null,
      loggedInUser: null,
      loggedInUserRoles: null,
      loggedInUserAccounts: null,
      selectedAccountId: null,
      googleCalendarConnected: false,
      reconnectGoogleCalendar: false,
      showQuickStart: false,
    };
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState: loadInitialState(),
  reducers: {
    setAuthInfo: (state, action) => {
      if (action.payload.token === null) {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        state.loggedIn = false;
        state.token = null;
        state.loggedInUser = null;
        state.loggedInUserRoles = null;
        state.loggedInUserAccounts = null;
        state.selectedAccountId = null;
        state.googleCalendarConnected = false;
        state.reconnectGoogleCalendar = false;
        state.showQuickStart = false;
      } else {
        state.loggedIn =
          !!action.payload.token && !!action.payload.loggedInUser?.timezone;
        state.token = action.payload.token;
        state.loggedInUser = action.payload.loggedInUser;
        state.loggedInUserRoles = action.payload.loggedInUserRoles;
        state.loggedInUserAccounts = action.payload.loggedInUserAccounts;
        state.selectedAccountId = action.payload.selectedAccountId;
        state.googleCalendarConnected = action.payload.googleCalendarConnected;
        state.reconnectGoogleCalendar = action.payload.reconnectGoogleCalendar;
        state.showQuickStart = action.payload.showQuickStart;
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
      }
    },
  },
  extraReducers: () => {},
});

export const { setAuthInfo } = authSlice.actions;

export default authSlice.reducer;
