export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new Error(`Invalid JWT token: ${error.message}`);
  }
}

export function isTokenExpired(token) {
  const decodedToken = parseJwt(token);
  const currentTimestamp = Math.floor(Date.now() / 1000);

  return decodedToken.exp < currentTimestamp;
}

export function isTokenExpiringWithinTenMinutes(token) {
  const decodedToken = parseJwt(token);
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const tenMinutesFromNow = currentTimestamp + 600;

  return decodedToken.exp < tenMinutesFromNow;
}

export function getCurrentTime(value) {
  const date = new Date(value);
  let hour = date.getHours();
  if (hour < 10) {
    hour = "0" + hour;
  }
  let minute = date.getMinutes();
  if (minute < 10) {
    minute = "0" + minute;
  }
  return hour + ":" + minute;
}

export function getFullDate(timeValue) {
  if (timeValue === "") return null;
  const date = new Date();
  const hour = parseInt(timeValue.split(":")[0]);
  const minute = parseInt(timeValue.split(":")[1]);
  date.setHours(hour, minute, 0);
  return date;
}

export function getOnlyDateValue(dateTime) {
  const date = new Date(dateTime);
  date.setHours(0);
  return date;
}
