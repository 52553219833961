// userApi.js
import { api } from "./api.js";
import apiUrl from "../config/config.js";

export const ROLE_TEACHER = "ROLE_TEACHER";
export const ROLE_STUDENT = "ROLE_STUDENT";
export const ROLE_PARENT = "ROLE_PARENT";

export const createNewUser = async (user, accountId, roleName) => {
  try {
    const params = new URLSearchParams();
    params.append("account_id", accountId);
    params.append("role_name", roleName);

    const response = await api.post(
      `${apiUrl}/api/v1/user?${params.toString()}`,
      user
    );

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw new Error(`Error creating user: ${error.message}`);
  }
};

export const getUsers = async (accountId, role) => {
  try {
    const params = {
      account_id: accountId,
    };
    if (role) {
      params.role = role;
    }
    const response = await api.get(`${apiUrl}/api/v1/user`, {
      params,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw error;
  }
};

export const getUserById = async (id, accountId) => {
  try {
    const params = {
      account_id: accountId,
    };

    const response = await api.get(`${apiUrl}/api/v1/user/${id}`, {
      params,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw error;
  }
};

export const getStudentEnrollment = async (id) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/student/${id}`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw error;
  }
};

export const getInviteUsers = async (
  accountId,
  role,
  page = 0,
  size = 10,
  sort = "ASC"
) => {
  try {
    const params = {
      accountId: accountId,
      page: page,
      size: size,
      sort: sort,
      role_name: role,
    };
    const response = await api.get(`${apiUrl}/api/v1/user-invite`, {
      params,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw error;
  }
};

export const deleteInviteUser = async (id) => {
  try {
    const response = await api.delete(`${apiUrl}/api/v1/user-invite/${id}`);

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw new Error(`Error deleting invite user: ${error.message}`);
  }
};

export const deleteUser = async (accountId, userId) => {
  try {
    const params = {
      account_id: accountId,
    };
    const response = await api.delete(`${apiUrl}/api/v1/user/${userId}`, {
      params,
    });

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw new Error(`Error deleting user: ${error.message}`);
  }
};

export const updateUserTimezone = async (userId, timezone) => {
  try {
    const params = new URLSearchParams();
    params.append("timezone", timezone);

    const response = await api.put(
      `${apiUrl}/api/v1/user/timezone/${userId}?${params.toString()}`
    );

    if (response.status >= 200 && response.status < 300) {
      const data = response.data;
      return data;
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    throw new Error(`Error creating user: ${error.message}`);
  }
};
