import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { Formik, Form, Field } from "formik";
import { createClassValidationSchema } from "../../utils/validation";
import {
  createClassTemplate,
  updateClassTemplate,
} from "../../api/classTemplateApi";
import { addClassTemplate } from "../../redux/classTemplateSlice";
import { useReportContext } from "../../contexts/report-context";

const SUBJECTS = [
  "English",
  "Math",
  "Science",
  "History",
  "Language Arts",
  "Reading",
  "Writing",
  "Spelling",
  "Art",
  "Music",
  "Physics",
  "Physical Education",
  "Health",
  "Foreign Language",
  "Computer Science",
  "Home Economics",
  "Shop",
  "Social Studies",
  "Agriculture",
  "Business",
  "Accounting",
  "Life Skills",
  "Philosophy",
  "Psychology",
  "Sociology",
  "Anthropology",
  "Geography",
  "Political Science",
  "Economics",
  "Civics",
  "Government",
  "Law",
  "Education",
];

const CreateClassModal = ({ open, handleUpdate, handleClose, data }) => {
  const [lessonFrequency, setLessonFrequency] = useState("DAILY");
  const [numberOfLessonsType, setNumberOfLessonsType] = useState("Ongoing");
  const dispatch = useDispatch();
  const { setMessage } = useReportContext();

  useEffect(() => {
    // Call a function to reset the state of the controls
    resetControls();
  }, []);

  const resetControls = () => {
    // Reset the state of the controls
    setLessonFrequency("DAILY");
    setNumberOfLessonsType("Ongoing");
  };

  if (!open) {
    return null;
  }

  const handleCancel = () => {
    handleClose();
    resetControls();
  };

  const handleCreateClass = async (values) => {
    const { name, subject, description, durationPerLesson, numberOfLessons } =
      values;
    try {
      const classData = {
        name: name,
        subject: subject,
        description: description,
        durationPerLesson: durationPerLesson,
        numberOfLessons:
          numberOfLessonsType === "Fixed Number" ? numberOfLessons : null,
        lessonFrequency: lessonFrequency,
        isOngoing: numberOfLessonsType === "Fixed Number" ? false : true,
      };
      if (data && data.id) {
        await updateClassTemplate(data.id, classData);
        handleUpdate();
        setMessage({
          text: "Updated the class template successfully!",
          severity: "success",
          flag: true,
        });
      } else {
        const response = await createClassTemplate(classData);
        dispatch(addClassTemplate(response));
        handleClose();
        resetControls();
        setMessage({
          text: "Created the class template successfully!",
          severity: "success",
          flag: true,
        });
      }
    } catch (err) {
      setMessage({
        text: "Failed to update the class template",
        severity: "error",
        flag: true,
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="class-modal-title"
      aria-describedby="class-modal-description"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        {data ? "Edit" : "Create"} Class
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }}>
        <Formik
          onSubmit={handleCreateClass}
          initialValues={{
            name: data ? data.name : "",
            subject: data ? data.subject : "",
            description: data ? data.description : "",
            durationPerLesson: data ? data.durationPerLesson : 0,
            numberOfLessons: data ? data.numberOfLessons : 0,
            lessonFrequency: lessonFrequency,
            numberOfLessonsType: numberOfLessonsType,
          }}
          validationSchema={createClassValidationSchema}
        >
          {({ isSubmitting, touched, errors, values, setValues }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Title"
                          size="small"
                          fullWidth
                          error={errors.name && touched.name}
                          helperText={
                            errors.name && touched.name ? errors.name : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="description">
                      {({ field }) => (
                        <TextField
                          {...field}
                          label="Description"
                          size="small"
                          fullWidth
                          multiline
                          rows={4}
                          error={errors.description && touched.description}
                          helperText={
                            errors.description && touched.description
                              ? errors.description
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="subject">
                      {({ field }) => (
                        <Autocomplete
                          freeSolo
                          options={SUBJECTS}
                          onChange={(event, newValue) => {
                            setValues({
                              ...values,
                              subject: newValue,
                            })
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              label="Subject"
                              size="small"
                              fullWidth
                              error={errors.subject && touched.subject}
                              helperText={
                                errors.subject && touched.subject
                                  ? errors.subject
                                  : ""
                              }
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="durationPerLesson">
                      {({ field }) => (
                        <NumericFormat
                          customInput={TextField}
                          variant="outlined"
                          label="Class Duration (in minutes)"
                          size="small"
                          InputProps={{
                            inputComponent: NumericFormat,
                            inputProps: {
                              ...field,
                            },
                          }}
                          sx={{ width: "100%" }}
                          displayType="input"
                          allowNegative={false}
                          value={values.durationPerLesson}
                          onValueChange={(val) =>
                            setValues({
                              ...values,
                              durationPerLesson: val.floatValue,
                            })
                          }
                          error={
                            errors.durationPerLesson &&
                            touched.durationPerLesson
                          }
                          helperText={
                            errors.durationPerLesson &&
                            touched.durationPerLesson
                              ? errors.durationPerLesson
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Lesson Frequency</FormLabel>
                      <RadioGroup
                        name="lessonFrequency"
                        value={lessonFrequency}
                        onChange={(event) =>
                          setLessonFrequency(event.target.value)
                        }
                      >
                        <FormControlLabel
                          value="DAILY"
                          control={<Radio />}
                          label="Each instructional day"
                        />
                        <FormControlLabel
                          value="EVERY_OTHER_DAY"
                          control={<Radio />}
                          label="Every other instructional day"
                        />
                        <FormControlLabel
                          value="MONDAY_WEDNESDAY_FRIDAY"
                          control={<Radio />}
                          label="Monday—Wednesday—Friday"
                        />
                        <FormControlLabel
                          value="TUESDAY_THURSDAY"
                          control={<Radio />}
                          label="Tuesday—Thursday"
                        />
                        <FormControlLabel
                          value="WEEKLY"
                          control={<Radio />}
                          label="Weekly"
                        />
                        <FormControlLabel
                          value="EVERY_OTHER_WEEK"
                          control={<Radio />}
                          label="Every other week"
                        />
                        <FormControlLabel
                          value="MONTHLY"
                          control={<Radio />}
                          label="Monthly"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Number of lessons
                      </FormLabel>
                      <Grid container alignItems="flex-end">
                        <Grid item>
                          <RadioGroup
                            name="numberOfLessonsType"
                            value={numberOfLessonsType}
                            onChange={(event) =>
                              setNumberOfLessonsType(event.target.value)
                            }
                          >
                            <FormControlLabel
                              value="Ongoing"
                              control={<Radio />}
                              label="Ongoing"
                            />
                            <FormControlLabel
                              value="Fixed Number"
                              control={<Radio />}
                              label="Fixed Number"
                            />
                          </RadioGroup>
                        </Grid>
                        {numberOfLessonsType === "Fixed Number" && (
                          <Grid item>
                            <Field name="numberOfLessons">
                              {({ field }) => (
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    inputComponent: NumericFormat,
                                    inputProps: {
                                      ...field,
                                    },
                                  }}
                                />
                              )}
                            </Field>
                          </Grid>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button autoFocus variant="outlined" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    {data ? "Save" : "Create"}
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default CreateClassModal;
