import { api } from "./api";
import apiUrl from "../config/config.js";

export const getClassTemplates = async (
  page = 0,
  size = 10,
  sortDirection = "ASC"
) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/class-template`, {
      params: {
        page,
        size,
        sortDirection,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassTemplateById = async (id) => {
  try {
    const response = await api.get(`${apiUrl}/api/v1/class-template/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchClassTemplates = async (
  page = 0,
  size = 10,
  sortDirection = "ASC",
  data
) => {
  const params = new URLSearchParams();
  params.append("page", page);
  params.append("size", size);
  params.append("sortDirection", sortDirection);
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/class-template/search?${params.toString()}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createClassTemplate = async (data) => {
  try {
    const response = await api.post(`${apiUrl}/api/v1/class-template`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateClassTemplate = async (id, data) => {
  try {
    const response = await api.put(
      `${apiUrl}/api/v1/class-template/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteClassTemplate = async (id) => {
  try {
    const response = await api.delete(`${apiUrl}/api/v1/class-template/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const applyClassTemplateToTerm = async (data) => {
  try {
    const response = await api.post(
      `${apiUrl}/api/v1/class-template/apply-to-term`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeClassTemplateFromTerm = async (
  classTemplateId,
  termId,
  studentId
) => {
  try {
    const response = await api.delete(
      `${apiUrl}/api/v1/class-template/remove-from-term/${classTemplateId}/${termId}?studentId=${studentId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getClassInstances = async (
  classTemplateId,
  page = 0,
  size = 10,
  sortDirection = "ASC"
) => {
  try {
    const response = await api.get(
      `${apiUrl}/api/v1/class-template/${classTemplateId}/class-instances`,
      {
        params: {
          page,
          size,
          sortDirection,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
