import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  MenuItem,
  Stack,
  Typography,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { applyClassTemplateToTerm } from "../../api/classTemplateApi";
import { useReportContext } from "../../contexts/report-context";
import { enrollUserValidationSchema } from "../../utils/validation";

const ApplyToTermModal = ({
  open,
  handleApply,
  handleClose,
  classTemplateId,
  classTemplateDetails,
  terms,
  students,
}) => {
  const { setMessage } = useReportContext();

  if (!open) {
    return null;
  }

  const handleSubmit = async (values) => {
    const { termId, studentId } = values;
    try {
      const data = {
        classTemplateId: classTemplateId,
        termId: termId,
        studentId: studentId,
      };
      await applyClassTemplateToTerm(data);
      handleApply();
      setMessage({
        text: "Applied the class template successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to apply the class template",
        severity: "error",
        flag: true,
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="apply-modal-title"
      aria-describedby="apply-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        Enroll Student in Class
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            termId: "",
            studentId: "",
          }}
          validationSchema={enrollUserValidationSchema}
        >
          {({ isSubmitting, touched, errors, values, setValues }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="studentId">
                      {({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Student"
                          size="small"
                          fullWidth
                          error={errors.studentId && touched.studentId}
                          helperText={
                            errors.studentId && touched.studentId
                              ? errors.studentId
                              : ""
                          }
                          required
                        >
                          {students.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.firstName} {option.lastName}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="termId">
                      {({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="School Term"
                          size="small"
                          fullWidth
                          error={errors.termId && touched.termId}
                          helperText={
                            errors.termId && touched.termId ? errors.termId : ""
                          }
                          required
                        >
                          {terms.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography variant="bodyFV">Class Title:</Typography>
                      <Typography variant="bodyFV">
                        {classTemplateDetails.name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography variant="bodyFV">Duration:</Typography>
                      <Typography variant="bodyFV">
                        {classTemplateDetails.durationPerLesson} minutes :{" "}
                        {classTemplateDetails.lessonFrequency}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      This class will be added to your calendar for the school
                      term you select below.
                    </Alert>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button autoFocus variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Schedule
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyToTermModal;
