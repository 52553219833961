import React, { useState, useEffect, useRef, useCallback } from "react";
import { fetchActiveTerms, fetchPastTerms } from "../../api/termApi.js";
import { useSelector } from "react-redux";
import { ROLE_STUDENT, getUsers } from "../../api/userApi";
import { useReportContext } from "../../contexts/report-context";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const ReportGenerator = () => {
  const [users, setUsers] = useState([]);
  const [terms, setTerms] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [reports, setReports] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const reportsRef = useRef();

  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  reportsRef.current = reports;

  const { setMessage } = useReportContext();

  const fetchStudents = useCallback(async () => {
    try {
      const users = await getUsers(selectedAccountId, ROLE_STUDENT);
      setUsers(users);
    } catch (error) {
      setMessage({
        text: "Failed to fetch students",
        severity: "error",
        flag: true,
      });
    }
  }, [selectedAccountId, setMessage]); // setMessage is now a dependency, but it has a stable identity

  useEffect(() => {
    // Fetch users and terms when the component mounts
    fetchStudents();

    // Fetch active and past terms when the component mounts
    fetchActiveTerms().then((response) =>
      setTerms((prevTerms) => [...prevTerms, ...response.terms])
    );
    fetchPastTerms().then((response) =>
      setTerms((prevTerms) => [...prevTerms, ...response.terms])
    );

    // TODO: Fetch reports when the component mounts
    // axios.get("/api/reports").then((response) => setReports(response.data));

    // Poll the API every 5 seconds if there are any pending reports
    const interval = setInterval(() => {
      if (reportsRef.current.some((report) => report.status === "PENDING")) {
        // axios.get("/api/reports").then((response) => setReports(response.data));
      }
    }, 5000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [fetchStudents]); // Empty dependency array means this effect runs once on mount and clean up on unmount

  const generateReport = () => {
    // Generate a report for the selected user and term
    // TODO: Implement this
  };

  const handleRowClick = (params) => {
    // TODO: Implement this
    // const classId = params.row.id;
    // handleOpenDetailsPage(classId);
  };

  const columns = React.useMemo(
    () => [
      { Header: "User", accessor: "user" },
      { Header: "School Term", accessor: "term" },
      { Header: "Date", accessor: "date" },
      { Header: "Status", accessor: "status" },
      { Header: "Download Link", accessor: "downloadLink" },
    ],
    []
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h4" gutterBottom>
                Reports
              </Typography>
              <Select
                size="small"
                fullWidth
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Student</em>;
                  }

                  const foundUser = users.find((item) => item.id === selected);
                  return `${foundUser?.firstName} ${foundUser?.lastName}`;
                }}
                onChange={setSelectedUser}
              >
                <MenuItem value="">All</MenuItem>
                {users.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
              </Select>
              <Select
                size="small"
                displayEmpty
                fullWidth
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Term</em>;
                  }
                  const foundTerm = terms.find((item) => item.id === selected);
                  return foundTerm?.name;
                }}
                onChange={setSelectedTerm}
              >
                <MenuItem value="">All</MenuItem>
                {terms.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>

            <Button variant="contained" onClick={generateReport}>
              Generate Report
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={reports}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: pageSize },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
              onRowClick={handleRowClick}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportGenerator;
