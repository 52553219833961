import React from "react";

const TermsOfService = () => (
  <div className="termsOfService">
    <h1>Terms of Service for HomeschoolPlanner.com</h1>

    <p>
      <strong>Last Updated:</strong> April 29, 2024
    </p>

    <h2>1. Agreement to Terms</h2>
    <p>
      By using our website, you agree to be bound by these Terms of Service and
      to use the site in accordance with these terms, our Privacy Policy, and
      any additional terms and conditions that may apply. HomeschoolPlanner.com
      is owned and operated by [Your Company Name]. These Terms apply to all
      visitors, users, and others who access or use the Service.
    </p>

    <h2>2. Use of Our Service</h2>
    <p>
      HomeschoolPlanner.com provides a platform for homeschooling
      administration, including but not limited to lesson planning, scheduling,
      and progress tracking. By using our Service, you agree to:
    </p>
    <ul>
      <li>
        Use the Service only for lawful purposes and in accordance with these
        Terms.
      </li>
      <li>
        Not use the Service in any way that could disable, overburden, damage,
        or impair the site or interfere with any other party’s use of the
        Service.
      </li>
      <li>
        Not attempt to gain unauthorized access to, interfere with, damage, or
        disrupt any parts of the Service, the server on which the Service is
        stored, or any server, computer, or database connected to the Service.
      </li>
      <li>
        Not use the Service for any commercial purposes without having all
        necessary rights and licenses.
      </li>
    </ul>

    <h2>3. Accounts</h2>
    <p>
      When you create an account with us, you must provide information that is
      accurate, complete, and current at all times. Failure to do so constitutes
      a breach of the Terms, which may result in immediate termination of your
      account on our Service.
    </p>

    <h2>4. Intellectual Property</h2>
    <p>
      The Service and its original content, features, and functionality are and
      will remain the exclusive property of [Your Company Name] and its
      licensors. Our trademarks and trade dress may not be used in connection
      with any product or service without the prior written consent of [Your
      Company Name].
    </p>

    <h2>5. Termination</h2>
    <p>
      We may terminate or suspend access to our Service immediately, without
      prior notice or liability, for any reason whatsoever, including without
      limitation if you breach the Terms. All provisions of the Terms which by
      their nature should survive termination shall survive termination,
      including, without limitation, ownership provisions, warranty disclaimers,
      indemnity, and limitations of liability.
    </p>

    <h2>6. Links to Other Web Sites</h2>
    <p>
      Our Service may contain links to third-party web sites or services that
      are not owned or controlled by HomeschoolPlanner.com. We have no control
      over, and assume no responsibility for, the content, privacy policies, or
      practices of any third party web sites or services. You further
      acknowledge and agree that HomeschoolPlanner.com shall not be responsible
      or liable, directly or indirectly, for any damage or loss caused or
      alleged to be caused by or in connection with use of or reliance on any
      such content, goods or services available on or through any such web sites
      or services.
    </p>

    <h2>7. Changes</h2>
    <p>
      We reserve the right, at our sole discretion, to modify or replace these
      Terms at any time. If a revision is material, we will try to provide at
      least 30 days' notice prior to any new terms taking effect. What
      constitutes a material change will be determined at our sole discretion.
    </p>

    <h2>8. Contact Us</h2>
    <p>
      If you have any questions about these Terms, please contact us at
      support@homeschoolplanner.com.
    </p>
  </div>
);

export default TermsOfService;
