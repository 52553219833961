import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Button,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserById, getStudentEnrollment } from "../../api/userApi";
import {
  fetchActiveTerms,
  fetchPastTerms,
  fetchFutureTerms,
} from "../../api/termApi";
import { getClassTemplates } from "../../api/classTemplateApi";
import { useReportContext } from "../../contexts/report-context";
import EnrollStudentModal from "./EnrollStudentModal";

const UserDetailsPage = () => {
  const { id } = useParams();
  const selectedAccountId = useSelector(
    (state) => state.auth.selectedAccountId
  );
  const { setMessage } = useReportContext();
  const [userDetails, setUserDetails] = useState(null);
  const [enrollmentData, setEnrollmentData] = useState([]);
  const [activeTerms, setActiveTerms] = useState([]);
  const [futureTerms, setFutureTerms] = useState([]);
  const [pastTerms, setPastTerms] = useState([]);
  const [openEnrollStudentModal, setOpenEnrollStudentModal] = useState(false);
  const [classTemplates, setClassTemplates] = useState([]);

  useEffect(() => {
    if (selectedAccountId) {
      fetchUserDetails();
    }
  }, [selectedAccountId]);

  useEffect(() => {
    fetchStudentEnrollment();
    fetchClassTemplates();
  }, []);

  const fetchAllSchoolTerms = useCallback(() => {
    // Fetch active terms
    fetchActiveTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setActiveTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch active terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch future terms
    fetchFutureTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setFutureTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch future terms",
          severity: "error",
          flag: true,
        });
      });

    // Fetch past terms
    fetchPastTerms(0, 100)
      .then(({ terms, totalPages }) => {
        setPastTerms(terms);
      })
      .catch((error) => {
        setMessage({
          text: "Failed to fetch past terms",
          severity: "error",
          flag: true,
        });
      });
  }, [setMessage]);

  useEffect(() => {
    fetchAllSchoolTerms();
  }, [fetchAllSchoolTerms]);

  const fetchUserDetails = async () => {
    try {
      const response = await getUserById(id, selectedAccountId);
      setUserDetails(response);
    } catch (err) {
      setMessage({
        text: "Failed to fetch user details",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchStudentEnrollment = async () => {
    try {
      const response = await getStudentEnrollment(id);
      const mappedData = response.map((item) => {
        return {
          id: item.classTemplateSummary.id,
          class: item.classTemplateSummary.name,
          subject: item.classTemplateSummary.subject,
          duration: item.classTemplateSummary.durationPerLesson,
          termId: item.termSummary.id,
          termName: item.termSummary.name,
        };
      });
      setEnrollmentData(mappedData);
    } catch (err) {
      setMessage({
        text: "Failed to fetch student details",
        severity: "error",
        flag: true,
      });
    }
  };

  const fetchClassTemplates = async () => {
    try {
      const data = await getClassTemplates(0, 100);
      setClassTemplates(data.content);
    } catch (err) {
      setMessage({
        text: "Failed to fetch class templates",
        severity: "error",
        flag: true,
      });
    }
  };

  const handleSelectTerm = (event) => {
    const termId = event.target.value;
    const originalEnrollment = enrollmentData;
    const filteredEnrollment = originalEnrollment.filter(
      (item) => item.termId === termId
    );
    setEnrollmentData(filteredEnrollment);
  };

  const handleOpenEnrollStudentModal = () => {
    setOpenEnrollStudentModal(true);
  };

  const handleCloseEnrollStudentModal = () => {
    setOpenEnrollStudentModal(false);
  };

  const handleEnrollStudent = () => {
    fetchStudentEnrollment();
    setOpenEnrollStudentModal(false);
  };

  const enrollmentColumns = [
    { field: "class", headerName: "Class", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "duration", headerName: "Duration", flex: 1 },
    { field: "termName", headerName: "Term", flex: 1 },
  ];

  return (
    <Box>
      {userDetails && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {userDetails.firstName} {userDetails.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant="bodyFV">Email:</Typography>
              <Typography variant="bodyFV">{userDetails.email}</Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6" gutterBottom>
                Enrollment
              </Typography>
              <Select
                size="small"
                displayEmpty
                fullWidth
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Term</em>;
                  }

                  const foundTerm = [
                    ...activeTerms,
                    ...futureTerms,
                    ...pastTerms,
                  ].find((item) => item.id === selected);
                  return foundTerm?.name;
                }}
                onChange={handleSelectTerm}
              >
                <MenuItem value="">All</MenuItem>
                {[...activeTerms, ...futureTerms, ...pastTerms].map(
                  (option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  )
                )}
              </Select>
            </Stack>

            <Button variant="contained" onClick={handleOpenEnrollStudentModal}>
              Add Class
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid
              rows={enrollmentData}
              columns={enrollmentColumns}
              autoHeight={true}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 15, 20]}
            />
          </Paper>
        </Grid>
      </Grid>
      <EnrollStudentModal
        open={openEnrollStudentModal}
        studentDetails={userDetails}
        terms={[...activeTerms, ...futureTerms]}
        handleClose={handleCloseEnrollStudentModal}
        handleApply={handleEnrollStudent}
        classes={classTemplates}
      />
    </Box>
  );
};

export default UserDetailsPage;
