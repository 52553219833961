import React from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  TextField,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import { applyClassTemplateToTerm } from "../../api/classTemplateApi";
import { useReportContext } from "../../contexts/report-context";
import { enrollStudentValidationSchema } from "../../utils/validation";

const EnrollStudentModal = ({
  open,
  handleApply,
  handleClose,
  studentDetails,
  classes,
  terms,
}) => {
  const { setMessage } = useReportContext();

  if (!open) {
    return null;
  }

  const handleSubmit = async (values) => {
    const { termId, classTemplateId } = values;
    try {
      const data = {
        classTemplateId: classTemplateId,
        termId: termId,
        studentId: studentDetails?.id,
      };
      await applyClassTemplateToTerm(data);
      handleApply();
      setMessage({
        text: "Applied the class template successfully!",
        severity: "success",
        flag: true,
      });
    } catch (err) {
      setMessage({
        text: "Failed to apply the class template",
        severity: "error",
        flag: true,
      });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="apply-modal-title"
      aria-describedby="apply-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle style={{ cursor: "move" }} id="dialog-title">
        Enroll Student in Class
      </DialogTitle>
      <DialogContent dividers sx={{ p: 2 }}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            termId: "",
            classTemplateId: "",
          }}
          validationSchema={enrollStudentValidationSchema}
        >
          {({
            isSubmitting,
            touched,
            errors,
            values,
            setValues,
            handleChange,
          }) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {studentDetails?.firstName} {studentDetails?.lastName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="classTemplateId">
                      {({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="Class"
                          size="small"
                          fullWidth
                          error={
                            errors.classTemplateId && touched.classTemplateId
                          }
                          helperText={
                            errors.classTemplateId && touched.classTemplateId
                              ? errors.classTemplateId
                              : ""
                          }
                          required
                        >
                          {classes.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {`${option.name} (${option.durationPerLesson} : ${option.lessonFrequency})`}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="termId">
                      {({ field }) => (
                        <TextField
                          {...field}
                          select
                          label="School Term"
                          size="small"
                          fullWidth
                          error={errors.termId && touched.termId}
                          helperText={
                            errors.termId && touched.termId ? errors.termId : ""
                          }
                          required
                        >
                          {terms.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Stack
                  direction={"row"}
                  justifyContent={"end"}
                  spacing={2}
                  sx={{ mt: 2 }}
                >
                  <Button autoFocus variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Schedule
                  </LoadingButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default EnrollStudentModal;
