// calendarEventsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const calendarEventsSlice = createSlice({
  name: "calendarEvents",
  initialState: { events: [] },
  reducers: {
    setCalendarEvents: (state, action) => {
      state.events = action.payload;
    },
    addCalendarEvent: (state, action) => {
      state.events.push(action.payload);
    },
  },
});

export const { setCalendarEvents, addCalendarEvent } =
  calendarEventsSlice.actions;

export default calendarEventsSlice.reducer;
