import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useReportContext } from "../contexts/report-context";

const AppSnackBar = ({ message }) => {
  const { setMessage } = useReportContext();

  const handleClose = () => {
    setMessage({ ...message, flag: false });
  };
  return (
    <Snackbar
      open={message.flag}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={message.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message.text}
      </Alert>
    </Snackbar>
  );
};

export default AppSnackBar;
